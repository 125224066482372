import { Layout, Menu, ConfigProvider, Button, Drawer, Steps, message, Upload, notification, Collapse, Spin, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { CloseOutlined, AppstoreOutlined, BarsOutlined, InboxOutlined, CheckCircleFilled, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import axios from 'axios';
import styled from 'styled-components';


import {
    ref,
    uploadBytes,
    getDownloadURL,
    listAll,
    FirebaseStorage,
    UploadResult,
    getStorage
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { initializeApp } from "firebase/app";
import FormTransport from './FormTransport';

const { Dragger } = Upload;
const { Panel } = Collapse;
const firebaseConfig = {
    apiKey: "AIzaSyB1L-aYQYwKOs1TSF-UukA7cSs5geKsN9Y",
    authDomain: "climat-api-db-dev.firebaseapp.com",
    projectId: "climat-api-db-dev",
    storageBucket: "climat-api-db-dev.appspot.com",
    messagingSenderId: "698461513612",
    appId: "1:698461513612:web:d68565f8b7e2f67f5c18a9",
    measurementId: ""
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

interface ImageInfo {
    url: string;
    name: string;
    realName: string;
    createdAt: string;
    nameBasic: string;
}

function replaceChar(inputString: string, targetChar: string, replacementChar: string): string {
    let result = "";
    for (let i = 0; i < inputString.length; i++) {
        if (i === 0) {
            result += replacementChar;
        } else if (i + 1 === inputString.length) {
            result += replacementChar;
        } else if (inputString[i] === targetChar) {
            result += replacementChar;
        } else {
            result += inputString[i];
        }
    }
    return result;
}


//{"food":[{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"},{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"}]}
//
//{"food":[{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"},{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"}]}
const ExtractObjectFromIA = (StringToExtract: string, type: string) => {
    // STRING TO EXTRACT {"food":[{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"},{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"},]}

    // Expect : {"food":[{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"}]}
    // Validation : {"food":[{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"}]}

    //{"food":[{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"},{"method":"Végétarien","nb_of_meals":"3","id_payment":"1"}]}
    const replacedString = replaceChar(StringToExtract, "'", "");
    if (replacedString === "['']") {
        return [];
    }
    console.log("STRING TO EXTRACT", replacedString);
    const ObjectFood = [];
    let ObjectToPushArray = "";

    let multipleChoice = false;
    for (let i = 0; i < replacedString.length; ++i) {
        if (i > 1 && replacedString[i] == ',' && replacedString[i - 1] === '}') {
            multipleChoice = true;
        } else {
            ObjectToPushArray += replacedString[i];
        }
    }
    console.log("VALIDATION", ObjectToPushArray);
    if (multipleChoice === false) {
        const Newarray = JSON.parse(ObjectToPushArray);
        ObjectFood.push(Newarray);
    } else {
        const Newarray = JSON.parse(replacedString);
        console.log("MULTIPLE, NEW ARRAY", Newarray);
        ObjectFood.push(Newarray);
    }
    return ObjectFood;
}

const FormateObjectFinal = (ArrayObject: any[], type: string) => {
    const ArrayToPush = [];
    try {
        if (Array.isArray(ArrayObject)) {
            const newArray = ArrayObject[0]?.[type];
            console.log("ARRAY NEW ARRAY", newArray, type);
            for (let i = 0; i < newArray.length; ++i) {
                ArrayToPush.push(newArray[i]);
            }
        } else {
            console.error("La chaîne JSON n'est pas un tableau.");
        }
    } catch (error) {
        console.error("Erreur d'analyse JSON :", error);
    }
    return ArrayToPush;
}

interface Transport {
    transport: any[];
}

interface Accomodation {
    accomodation: any[];
}

interface Activities {
    activities: any[];
}

interface Food {
    food: any[];
}

interface Payment {
    payment: any[];
}

interface RefreshInterface {
    Refresh: number;
    RefreshActualize: () => void;
    calculTransport: number;
    setCalculTransport: (value: number) => void;
}

const UploadFile = ({ Refresh, RefreshActualize, calculTransport, setCalculTransport }: RefreshInterface) => {
    const imagesListRef = ref(storage, 'iftm/images/');

    const [imageUrls, setImageUrls] = useState<ImageInfo[]>([]);
    const [imageUploads, setImageUploads] = useState<File[]>([]);
    const AllImageName: string[] = [];
    const [uploadTrigger, setUploadTrigger] = useState(false);

    const openNotificationVictory = () => {
        notification.open({
            message: '',
            description:
                <>
                    <h3>
                        <CheckCircleOutlined />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Votre fichier à bien été chargé<br />Et finit d'etre analyser.
                    </h3>
                </>,
            duration: 10,
            className: 'custom-class',
            style: {
                width: 400,
            },
        });
    };

    const openNotificationFailed = () => {
        notification.open({
            message: '',
            description:
                <>
                    <h3>
                        <ExclamationCircleOutlined />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Une erreur à été détecté dans votre fichier.<br />
                        Il n'a pas pu etre analyser.
                    </h3>
                </>,
            duration: 10,
            className: 'custom-class',
            style: {
                width: 400,
            },
        });
    };

    const openNotificationTwoFile = () => {
        notification.open({
            message: '',
            description:
                <>
                    <h3>
                        <ExclamationCircleOutlined />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Vous ne pouvez pas analyser 2 fichiers à la fois.<br />
                    </h3>
                </>,
            duration: 10,
            className: 'custom-class',
            style: {
                width: 400,
            },
        });
    };

    const ChooseObjectToExtract = (response: string, RefreshActualize: () => void) => {

        try {
            const AllObjects = response.split('\n');
            console.log("ALL OBJECTS", AllObjects);
            const ArrayFood = ExtractObjectFromIA(AllObjects[0], "food");
            const ArrayAccomodation = ExtractObjectFromIA(AllObjects[1], "accomodation");
            const ArrayTransport = ExtractObjectFromIA(AllObjects[2], "transport");
            const ArrayActivities = ExtractObjectFromIA(AllObjects[3], "activities");
            const ArrayPayments = ExtractObjectFromIA(AllObjects[4], "payment");
            const FinalArrayFood: Food = {
                food: FormateObjectFinal(ArrayFood, "food"),
            };

            const FinalArrayAccomodation: Accomodation = {
                accomodation: FormateObjectFinal(ArrayAccomodation, "accomodation"),
            };

            const FinalArrayTransport: Transport = {
                transport: FormateObjectFinal(ArrayTransport.slice(0, 1), "transport"),
            };

            const FinalArrayActivities: Activities = {
                activities: FormateObjectFinal(ArrayActivities, "activities"),
            };

            const FinalArrayPayment: Payment = {
                payment: FormateObjectFinal(ArrayPayments, "payment"),
            };

            console.log("FOOD", FinalArrayFood);
            console.log("ACCOMODATiON", FinalArrayAccomodation);
            console.log("TRANSPORT", FinalArrayTransport);
            console.log("ACTIVITIES", FinalArrayActivities);

            const OldFormTransport = localStorage.getItem('FormTransport');
            const OldFormAccomodation = localStorage.getItem('FormAccomodation');
            const OldFormActivities = localStorage.getItem('FormActivities');
            const OldFormFood = localStorage.getItem('FormFood');
            const OldFormPayment = localStorage.getItem('FormPayment');

            let FormattedAccomodation: Accomodation = {
                accomodation: [],
            };

            if (OldFormAccomodation) {
                const OldArrayParsedAccomodation = JSON.parse(OldFormAccomodation);
                if (OldArrayParsedAccomodation && Array.isArray(OldArrayParsedAccomodation.accomodation)) {
                    console.log("PLUSIEURS ACCOMODATION");
                    FormattedAccomodation.accomodation = [
                        ...OldArrayParsedAccomodation.accomodation,
                        ...FinalArrayAccomodation.accomodation
                    ]
                } else {
                    console.log("1 ACCOMODATION");
                    FormattedAccomodation = FinalArrayAccomodation;
                }
            }

            let FormattedTransport: Transport = {
                transport: [],
            };
            if (OldFormTransport) {
                const OldArrayParsedTransport = JSON.parse(OldFormTransport);
                if (OldArrayParsedTransport && Array.isArray(OldArrayParsedTransport.transport)) {
                    console.log("PLUSIEURS TRANSPORt");
                    FormattedTransport.transport = [
                        ...OldArrayParsedTransport.transport,
                        ...FinalArrayTransport.transport
                    ]
                } else {
                    console.log("1 TRANSPORT");
                    FormattedTransport = FinalArrayTransport;
                }
            }

            let FormattedActivities: Activities = {
                activities: [],
            };

            if (OldFormActivities) {
                const OldArrayParsedActivities = JSON.parse(OldFormActivities);
                if (OldArrayParsedActivities && Array.isArray(OldArrayParsedActivities.activities)) {
                    console.log("PLUSIEURS ACTIVITY");
                    FormattedActivities.activities = [
                        ...OldArrayParsedActivities.activities,
                        ...FinalArrayActivities.activities
                    ]
                } else {
                    console.log("1 ACTIVITIES");
                    FormattedActivities = FinalArrayActivities;
                }
            }

            let FormattedFood: Food = {
                food: [],
            };
            if (OldFormFood) {
                const OldArrayParsedFood = JSON.parse(OldFormFood);
                if (OldArrayParsedFood && Array.isArray(OldArrayParsedFood.food)) {
                    console.log("PLUSIEURS FOOD");
                    FormattedFood.food = [
                        ...OldArrayParsedFood.food,
                        ...FinalArrayFood.food
                    ]
                } else {
                    console.log("1 FOOD");
                    FormattedFood = FinalArrayFood;
                }
            }

            let FormattedPayment: Payment = {
                payment: [],
            };
            if (OldFormPayment) {
                const OldArrayParsedPayment = JSON.parse(OldFormPayment);
                if (OldArrayParsedPayment && Array.isArray(OldArrayParsedPayment.payment)) {
                    console.log("PLUSIEURS PAYMENTS");
                    console.log("OLD ARRAY ", OldArrayParsedPayment);
                    console.log("New Payments", FinalArrayPayment)
                    FormattedPayment.payment = [
                        ...OldArrayParsedPayment.payment,
                        ...FinalArrayPayment.payment
                    ]
                } else {
                    console.log("1 Payment");
                    FormattedPayment = FinalArrayPayment;
                }
            }

            console.log("FINAL TRANSPORT", FormattedTransport);
            console.log("FINAL ACCOMODATION", FormattedAccomodation);
            console.log("FINAL ACTIVITIES", FormattedActivities);
            console.log("FINAL FOOD", FormattedFood);

            localStorage.setItem('FormAccomodation', JSON.stringify(FormattedAccomodation));
            localStorage.setItem('FormTransport', JSON.stringify(FormattedTransport));
            localStorage.setItem('FormActivities', JSON.stringify(FormattedActivities));
            localStorage.setItem('FormFood', JSON.stringify(FormattedFood));
            localStorage.setItem('FormPayment', JSON.stringify(FormattedPayment));
            setCalculTransport(0);
            console.log("CALCUL TRANSPORT", calculTransport);
            console.log("VOILA LES PAYMENTS", FormattedPayment);
            openNotificationVictory();
            setLoadingImage(false);
            RefreshActualize();

        } catch (error) {
            setLoadingImage(false);
            openNotificationFailed();
        }
    }

    const [ArrayAllImageName, setArrayAllImageName] = useState<string[]>([]);

    const uploadFile = () => {
        if (imageUploads.length === 0) return;

        const promises = imageUploads.map((imageUpload) => {
            const uniqueIdentifier = uuidv4();
            const imageName = `${uniqueIdentifier}_${imageUpload.name}`;
            const imageRef = ref(storage as FirebaseStorage, `iftm/images/${imageName}`);
            AllImageName.push(imageUpload.name);
            console.log("NAME", AllImageName);
            const metadata = {
                customMetadata: {
                    realName: imageUpload.name,
                    name: imageName,
                    createdAt: new Date().toString(),
                },
            };

            //const formTransportMethod = JSON.parse(localStorage.getItem('FormImageUpload') || '[]');

            return uploadBytes(imageRef, imageUpload, metadata).then(
                (snapshot: UploadResult) => {
                    return getDownloadURL(snapshot.ref).then((url: string) => {
                        return { url, name: imageName, realName: imageUpload.name, createdAt: '', nameBasic: imageUpload.name };
                    });
                }
            );
        });

        Promise.all(promises)
            .then((results) => {
                const newImageUrls = results.map((result) => ({
                    url: result.url,
                    name: result.name,
                    realName: result.realName,
                    createdAt: result.createdAt,
                    nameBasic: result.nameBasic
                }));

                const lastImage = newImageUrls[newImageUrls.length - 1];

                setImageUrls([lastImage]);

                const updatedArray = [...ArrayAllImageName, lastImage.realName];
                setArrayAllImageName((prevArray) => [...prevArray, lastImage.realName]);
                localStorage.setItem('FormImageName', JSON.stringify(updatedArray));

                console.log("Dernière image", lastImage);

                setImageUploads([]);
                setUploadTrigger(true);
            })
            .catch((error) => {
                console.error('Error uploading files:', error);
            });
    };

    useEffect(() => {
        listAll(imagesListRef)
            .then((response) => {
                const promises = response.items.map((item) => {
                    return getDownloadURL(item)
                        .then((url) => {
                            return (item as any).getMetadata().then((metadata: any) => {
                                return {
                                    url,
                                    name: metadata.customMetadata.name,
                                    createdAt: metadata.customMetadata.createdAt,
                                };
                            });
                        })
                        .catch((error) => {
                            return null;
                        });
                });
                Promise.all(promises).then((results) => {
                    const validResults = results.filter((result) => result !== null) as ImageInfo[];
                    //setImageUrls(validResults);
                });
            })
            .catch((error) => {
                //openNotificationFailed();
                //setLoadingImage(false);
                return null;
            });
    }, []);

    useEffect(() => {
        if (uploadTrigger) {
            console.log("RESULT URL", imageUrls);
            EngineIA();
            setImageUrls([]);
            setImageUploads([]);
            setUploadTrigger(false);
        }
    }, [uploadTrigger]);

    const EngineIA = async () => {
        let AllObjects = ""
        try {
            const imageUrlsArray = imageUrls.map((imageUrl) => imageUrl.url);
            const queryParams = {
                imageUrls: imageUrlsArray,
            };
            console.log("IMAGE URL", imageUrls);
            const response = await axios.get('https://backiftm.azurewebsites.net//analyzer/PostUrlImageToIA', {
                params: queryParams,
            });
            AllObjects = response.data;

        } catch (error) {
            console.error('Error sending data to the backend:', error);
        }
        setImageUrls([]);
        setImageUploads([]);
        console.log("OBJECT EXTRACT FROM BACK", AllObjects);
        ChooseObjectToExtract(AllObjects, RefreshActualize);
    }

    const [uploadFileState, setUploadFileState] = useState(false);

    const clearImageUrls = () => {
        setImageUrls([]);
    };

    const [loadingImage, setLoadingImage] = useState(false);

    const draggerProps = {
        name: 'file',
        multiple: true,
        customRequest: () => { },
        showUploadList: false,
        async onChange(info: any) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            if (loadingImage === true) {
                openNotificationTwoFile();
            } else {
                clearImageUrls();
                setLoadingImage(true);
                if (info.fileList.length > 0) {
                    setImageUploads(info.fileList.map((fileItem: any) => fileItem.originFileObj));
                    setUploadFileState(true);
                    setLoadingImage(true);
                }
            }
        },

        onDrop(e: any) {
            if (loadingImage === true) {
                openNotificationTwoFile();
            } else {
                console.log('Dropped files', e.dataTransfer.files);
                clearImageUrls();
                setLoadingImage(true);
            }
        },

    };

    useEffect(() => {
        console.log(uploadFileState);
        if (uploadFileState) {
            setImageUploads([]);
            uploadFile();
            console.log("RESULT URL", imageUploads);
            setUploadFileState(false);
        }
    }, [uploadFileState]);


    const [nameTravel, setNameTravel] = useState('');

    const changeNameTravel = (input: string) => {
        console.log("NEW NAME TRAVEL", input);
        localStorage.setItem('NameTravel', input);
    }

    useEffect(() => {
        const AllImageUpload = JSON.parse(localStorage.getItem('FormImageName') || '[]');
        setArrayAllImageName(AllImageUpload);
    }, [Refresh]);

    useEffect(() => {
        const NameTravel = localStorage.getItem('NameTravel');
        if (NameTravel !== null) {
            console.log("WOW RESULT NAME", NameTravel);
            setNameTravel(NameTravel);
        }
        console.log("NEW NAME TRAVEL AFTER FINISH", NameTravel);

    }, [Refresh])

    return (
        <div>
            <FormTransport Refresh={Refresh} RefreshActualize={RefreshActualize} calculTransport={calculTransport} setCalculTransport={setCalculTransport} />
            <Dragger {...draggerProps} style={draggerStyle}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ fontSize: '36px' }} />
                </p>
                <h2>Add up to 5 invoices</h2>
                <p className="ant-upload-hint">
                The invoices will be read and used to pre-fill the form.
                </p>
            </Dragger>
            <br />
            Trip title
            <Input
                placeholder={nameTravel}
                onChange={(e) => changeNameTravel(e.target.value)}
                style={{ flex: 1, marginTop: '10px' }}
            />

            <Collapse style={{ maxHeight: '100px', overflowY: 'auto', marginTop: '10px' }}>
                <Panel
                    header={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>Selected files</span>
                            {loadingImage ? (
                                <Spin />
                            ) : (
                                <CheckCircleOutlined style={{ color: 'green', fontSize: '24px' }} />
                            )}

                        </div>
                    }
                    key="1"
                >
                    <ul>
                        {ArrayAllImageName.map((imageName, index) => (
                            <li key={index}>{imageName}</li>
                        ))}
                    </ul>
                </Panel>
            </Collapse>
        </div>
    );
};

const draggerStyle = {
    width: '400px',
};

export default UploadFile;
