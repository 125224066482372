import React from 'react';
import { Button, Menu, Layout, Modal} from 'antd';
import type { MenuProps } from 'antd';
import { faGaugeSimple, faGlobeEurope, faSeedling } from '@fortawesome/free-solid-svg-icons';
import { faRectangleList } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BankOutlined,
  CloudOutlined,
  EuroOutlined,
  HistoryOutlined,
  HomeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import LogoInline from '../atoms/Logo';
import UserMenu from './UserMenu';

type MenuItem = Required<MenuProps>['items'][number];

const styles = {
  menu: { paddingBottom: '48px' },
};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}


const items: MenuItem[] = [
  getItem('Dashboard', '1', <HomeOutlined />),
  getItem('My causes', '2', <FontAwesomeIcon icon={faGlobeEurope}/>),
  getItem('History', '3', <HistoryOutlined/>),
  getItem('Accounts', '4', <FontAwesomeIcon icon={faRectangleList}/>),
  getItem('Operations', '5', <EuroOutlined/>),
  getItem('Carbon synthesis', '6', <FontAwesomeIcon icon={faGaugeSimple}/>),
  getItem('Carbon photo', '7', <FontAwesomeIcon icon={faSeedling}/>),
];

const StyledMenu = styled(Menu)`
  background-color: #DCEEEB;
  color: grey;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 56px !important;

  & span[role='img'],
  & svg {
    font-size: 16px !important;
  }

  & .ant-menu-sub {
    color: green;
  }

  & .ant-menu-title-content {
    margin-left: 12px !important;
  }

  & li.ant-menu-item {
    position: relative;
    background-color: inherit !important;
  }

  & li.ant-menu-item span,
  & li.ant-menu-item svg,
  & li.ant-menu-item span,
  & li.ant-menu-item svg {
    z-index: 1 !important;
  }

  & li.ant-menu-item::before {
    content: '';
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0);
    top: calc((100% - 32px) / 2);
    left: 16px;
    border-radius: 40px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }

  & li.ant-menu-item:hover::before {
    background: rgba(255, 255, 255, 0.75);
  }

  & li.ant-menu-item-selected::before {
    background: rgba(255, 255, 255, 1) !important;
    width: calc(100% - 24px);
    height: 100%;
    top: 0;
    left: 12px;
  }

  & li.ant-menu-item-selected::after {
    display: none;
  }

  & li.ant-menu-submenu > div {
    background-color: inherit !important;
  }

  & .ant-menu-submenu li.ant-menu-item::before {
    content: '';
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0);
    top: calc((100% - 32px) / 2);
    left: 36px;
    border-radius: 40px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }

  & .ant-menu-submenu li.ant-menu-item:hover::before {
    background: rgba(255, 255, 255, 0);
  }

  & .ant-menu-submenu li.ant-menu-item-selected::before {
    background: rgba(255, 255, 255, 1) !important;
    width: calc(100% - 60px);
    height: calc(100% - 12px);
    top: calc((100% - 28px) / 2);
    left: 36px;
  }
`;

const OrganizationName = styled.div`
  background-color: #22778E;
  color: white;
  text-align: center;
  padding: 4px 24px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LogoKeewe = styled(LogoInline)`
  padding: 24px 24px 24px 24px;
  text-align: center;
`;

const Sider = styled(Layout.Sider)`
  overflow: auto;
  height: 100vh; /* Adjust this height as needed */
  width: 244px; /* Adjust this width as needed */
  position: fixed;
  left: 0;
  top: 0;
  background-color: #DCEEEB !important;
`;



function Sidebar() {
  return (
      <Sider width={244}>
      <LogoKeewe color="black"></LogoKeewe>

      <OrganizationName>
        Adrien Farras
      </OrganizationName>
      <StyledMenu
      defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        style={styles.menu}
        items={items}>
      </StyledMenu>
          <UserMenu />
      
      </Sider>
  );
}

export default Sidebar;