import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Button, Progress, Tooltip, Space, Card, Col, Row, Result } from 'antd';
import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import FormAccomodation from '../molecules/FormAccomodation';
import ExpandableMenuAllTravels from '../atoms/ExpandableMenuAllTravels';
import type { ColumnsType } from 'antd/es/table';
import ResumeInfoTravel from '../molecules/ResumeInfoTravel';

const { Column } = Table;

type TravelData = {
  key: React.Key;
  id: number;
  name: string;
  co2emis: number;
  co2compense: number;
  co2emistransport: number;
  co2emisaccomodation: number;
  co2emisactivities: number;
  co2emisfood: number;
  description: string;
  expandable: boolean;
};

interface RefreshInterface {
  Refresh: number;
  RefreshActualize: () => void;
}

const TravelPage = ({ Refresh, RefreshActualize }: RefreshInterface) => {
  const [allCarbonFootprint, setallCarbonFootprint] = useState('0.00');

  const [travelData, setTravelData] = useState<TravelData[]>([]);

  const [openInfoTravel, setopenInfoTravel] = useState(false);
  const [indexOpenInfoTravel, setindexOpenInfoTravel] = useState(0);

  useEffect(() => {
    const tableAllTravels = localStorage.getItem('TableAllTravels');
    let TotalFootprint = 0.00;
    if (tableAllTravels !== null) {
      const newTable = JSON.parse(tableAllTravels)

      for (let i = 0; i < newTable.length; ++i) {
        newTable[i].description = "abc";
        newTable[i].id = i.toString();
        newTable[i].expandable = false;
        TotalFootprint += parseFloat(newTable[i].co2emis);
      }
      setTravelData(newTable);
      const TotalFootPrintFixed = TotalFootprint.toFixed(2);
      setallCarbonFootprint(TotalFootPrintFixed.toString());
    }
  }, [Refresh]);

  const handleDelete = (key: number) => {
    const tableAllTravels = localStorage.getItem('TableAllTravels');
    if (tableAllTravels !== null) {
      const newTable = JSON.parse(tableAllTravels);
      newTable.splice(key, 1);
      console.log(newTable);
      setTravelData(newTable);
      localStorage.setItem('TableAllTravels', JSON.stringify(newTable));
    }
  };

  const handleInfoTravel = (key: number) => {
    setopenInfoTravel(true);
    setindexOpenInfoTravel(key);
  }

  return (
    <Container>
      <h1>My trips</h1>
      <Table
        dataSource={travelData}
        pagination={false}
      >
        <Column title="Title" dataIndex="intitulé" key="intitulé" />
        <Column title="Co2 Emission Rate" dataIndex="co2emis" key="co2emis" />
        <Column
          title="Actions"
          key="actions"
          render={(text, record: TravelData, index) => (
            <Space size="middle">
              <Button
                type="primary"
                icon={<EyeFilled />}
                onClick={() => handleInfoTravel(index)}
              >
              </Button>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(index)}
              >
              </Button>
            </Space>
          )}
        />
      </Table>
      {/*<h2>Empreinte carbone de mes voyages : {allCarbonFootprint} tCO2e</h2>*/}

      {openInfoTravel && (
        <ResumeInfoTravel index={indexOpenInfoTravel} toggleOpen={setopenInfoTravel} openInfoTravel={openInfoTravel} />
      )}

      
    </Container>

  );
}

export default TravelPage;

const Container = styled.div`
  & > span:first-of-type {
    display: block;
    font-size: 32px;

    &:hover {
      color: red;
    }
  }
`;

const GreenButton = styled(Button)`
  background-color: #22778E;
  color: white;
  border-color: #22778E;
`;
