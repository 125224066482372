export const AccomodationCountry = [
    "Argentina",
    "Australia",
    "Austria",
    "Belgium",
    "Brazil",
    "Canada",
    "Chile",
    "China",
    "Colombia",
    "Costa Rica",
    "Czech Republic",
    "Egypt",
    "Fiji",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hong Kong, China",
    "India",
    "Indonesia",
    "Ireland",
    "Israel",
    "Italy",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Korea",
    "Macao, China",
    "Malaysia",
    "Maldives",
    "Mexico",
    "Netherlands",
    "New Zealand",
    "Oman",
    "Panama",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Saudi Arabia",
    "Singapore",
    "South Africa",
    "Spain",
    "Switzerland",
    "Taiwan, China",
    "Thailand",
    "Turkey",
    "United Arab Emirates",
    "United States",
    "Vietnam"
]
