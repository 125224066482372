import React from 'react';
import { BankOutlined, DoubleRightOutlined, LogoutOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import styled from 'styled-components';

function UserMenu() {
    return (
        <Container>
            <Dropdown menu={{ items: []}} trigger={['click']} placement="topCenter" overlayClassName="dropdown-user" disabled>
                <ContainerDropDown $size={3}>
                    <div>
                        <AvatarIcon shape="circle" icon={<UserOutlined />} type="primary" />
                        <div>
                            <span>Adrien Farras</span>
                        </div>
                    </div>
                    <DoubleRightOutlined />
                </ContainerDropDown>
            </Dropdown>
        </Container>
    )
}

export default UserMenu;


const Container = styled.div`
position: fixed;
bottom: 0;
z-index: 1;
border-top: 1px solid #B9B9B9;
background-color: #DCEEEB;
`;

const AvatarIcon = styled(Button)`
  margin-right: 8px;
`;


const ContainerDropDown = styled.div<{ $size: number }>`
  position: relative;
  display: flex;
  gap: 8px;
  width: 244px;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    z-index: 2;
    flex-grow: 1;
    overflow: hidden;
  }

  & > div > div {
    display: inline-flex;
    flex-direction: column;
    line-height: 100%;
    flex-grow: 1;
    overflow: hidden;
  }

  & > div > div > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s ease;
  }

  & > div > div > span:first-child {
    font-size: 16px;
    font-weight: 600;
    color: #22778E;
  }

  & > span[role='img'] {
    transition: all 0.3s ease;
  }

  &::before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    bottom: 12px;
    left: 24px;
    display: block;
    border-radius: 50%;
    background-color: #22778E;
    transition: all 0.3s ease;
  }

  &.ant-dropdown-open::before {
    width: 220px;
    height: calc(44px + 14px + 38px * ${({ $size }) => $size});
    bottom: 10px;
    left: 12px;
    border-radius: 6px;
    background-color: #22778E;
  }

  &.ant-dropdown-open div > div > span {
    color: white;
  }

  &.ant-dropdown-open > span[role='img'] {
    color: #22778E;
    transform: translate(8px);
  }

  &:hover > span[role='img'] {
    color: #22778E;
    transform: translate(8px);
  }
`;
