import { Layout, Menu, ConfigProvider, Button, Drawer, Steps, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { MessageOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCocktail, faHome, faLeaf, faPersonSkiing, faPlane } from '@fortawesome/free-solid-svg-icons';

/*function GetCalculCo2Actual() {
    const [TotalTransport, setTotalTransport] = useState('');
    const [TotalAccomodation, setTotalAccomodation] = useState('');
    const [TotalActivities, setTotalActivities] = useState('');
    const [TotalFood, setTotalFood] = useState('');
    const [TotalCo2Emis, setTotalCo2Emis] = useState('');

    useEffect(() => {
        const formAccomodation = JSON.parse(localStorage.getItem('FormAccomodation') || '{}');
        const formActivities = JSON.parse(localStorage.getItem('FormActivities') || '{}');
        const formFood = JSON.parse(localStorage.getItem('FormFood') || '{}');
        const formPayment = JSON.parse(localStorage.getItem('FormPayment') || '{}');
        const formTransportDistance = JSON.parse(localStorage.getItem('FormTransportDistance') || '{}');
        const formTransportMethod = JSON.parse(localStorage.getItem('FormTransportMethod') || '{}');
        const formTransport = JSON.parse(localStorage.getItem('FormTransport') || '{}');

        const transformFormAccomodation = formAccomodation.accomodation;
        const transformFormActivities = formActivities.activities;
        const transformFormFood = formFood.food;
        const transformFormPayment = formPayment.payment;
        const transformFormTransport = formTransport.transport;

        const requestData = {
            FormAccomodation: transformFormAccomodation,
            FormActivities: transformFormActivities,
            FormFood: transformFormFood,
            FormPayment: transformFormPayment,
            FormTransportDistance: formTransportDistance,
            FormTransportMethod: formTransportMethod,
            FormTransport: transformFormTransport
        };

        const fetchData = async () => {
            try {
                const response = await axios.post('https://backiftm.azurewebsites.net//voyage/CalculAllCategories', requestData);
                console.log('Response from the backend:', response.data);
                setTotalAccomodation(response.data.totalAccomodation);
                setTotalActivities(response.data.totalActivities);
                setTotalFood(response.data.totalFood);
                setTotalCo2Emis(response.data.totalCo2Emis);
                setTotalTransport(response.data.totalTransport);
            } catch (error) {
                console.error('Error sending data to the backend:', error);
            }
        };

        fetchData();
    }, []);

    return {
        TotalTransport,
        TotalAccomodation,
        TotalActivities,
        TotalFood,
        TotalCo2Emis,
    };
}*/

function GetTcO2Actual({ Refresh }: { Refresh: number }) {
    const [TotalTransport, setTotalTransport] = useState('');
    const [TotalAccomodation, setTotalAccomodation] = useState('');
    const [TotalActivities, setTotalActivities] = useState('');
    const [TotalFood, setTotalFood] = useState('');
    const [TotalCo2Emis, setTotalCo2Emis] = useState('');

    useEffect(() => {
        const formAccomodation = JSON.parse(localStorage.getItem('FormAccomodation') || '{}');
        const formActivities = JSON.parse(localStorage.getItem('FormActivities') || '{}');
        const formFood = JSON.parse(localStorage.getItem('FormFood') || '{}');
        const formPayment = JSON.parse(localStorage.getItem('FormPayment') || '{}');
        const formTransportDistance = JSON.parse(localStorage.getItem('FormTransportDistance') || '{}');
        const formTransportMethod = JSON.parse(localStorage.getItem('FormTransportMethod') || '{}');
        const formTransport = JSON.parse(localStorage.getItem('FormTransport') || '{}');

        console.log("THE FORM Transport", formTransport);

        const transformFormAccomodation = formAccomodation.accomodation;
        const transformFormActivities = formActivities.activities;
        const transformFormFood = formFood.food;
        const transformFormPayment = formPayment.payment;
        const transformFormTransport = formTransport.transport;

        const requestData = {
            FormAccomodation: transformFormAccomodation,
            FormActivities: transformFormActivities,
            FormFood: transformFormFood,
            FormPayment: transformFormPayment,
            FormTransportDistance: formTransportDistance,
            FormTransportMethod: formTransportMethod,
            FormTransport: transformFormTransport
        };

        const fetchData = async () => {
            try {
                const response = await axios.post('https://backiftm.azurewebsites.net//voyage/CalculAllCategories', requestData);
                console.log('Response from the backend:', response.data);
                setTotalAccomodation(response.data.totalAccomodation);
                setTotalActivities(response.data.totalActivities);
                setTotalFood(response.data.totalFood);
                setTotalCo2Emis(response.data.totalCo2Emis);
                setTotalTransport(response.data.totalTransport);
            } catch (error) {
                console.error('Error sending data to the backend:', error);
            }
        };

        fetchData();
    }, [Refresh]);

    return (
        <>
            <Card style={{ marginLeft: '10px', width: 260, height: '350px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Card style={StyleCard}>
                        <FontAwesomeIcon icon={faPlane} size="1x" style={{ color: '#22778E' }} />
                    </Card>
                    <h2 style={StyleText}>
                        {TotalTransport} kg Co2e
                    </h2>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Card style={StyleCard}>
                        <FontAwesomeIcon icon={faHome} size="1x" style={{ color: '#22778E' }} />
                    </Card>
                    <h2 style={StyleText}>
                        {TotalAccomodation} kg Co2e
                    </h2>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Card style={StyleCard}>
                        <FontAwesomeIcon icon={faPersonSkiing} size="1x" style={{ color: '#22778E' }} />
                    </Card>
                    <h2 style={StyleText}>
                        {TotalActivities} kg Co2e
                    </h2>
                </div>


                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Card style={StyleCard}>
                        <FontAwesomeIcon icon={faCocktail} size="1x" style={{ color: '#22778E' }} />
                    </Card>
                    <h2 style={StyleText}>
                        {TotalFood} kg Co2e
                    </h2>
                </div>


                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <Card style={StyleCard}>
                        <FontAwesomeIcon icon={faLeaf} size="1x" style={{ color: '#22778E' }} />
                    </Card>
                    <h2 style={StyleText}>
                        {TotalCo2Emis} kg Co2e
                    </h2>
                </div>

            </Card>

        </>
    )
}

const StyleCard = {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #08262E',
    marginTop: '3px'
};

const StyleText = {
    marginTop: '10px',
    marginLeft: '10px',
};

export default GetTcO2Actual;
