import { CheckCircleFilled } from '@ant-design/icons';
import { Layout, Menu, ConfigProvider, Button, Drawer, Steps, Table, notification, Modal, Card, Result } from 'antd';
import { Console } from 'console';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormTransport from './FormTransport';
import ReactDOMServer from 'react-dom/server';

interface Payments {
    name: string;
    montant: string;
    contre_valeur_euros: string;
    devise: string;
    taux: string;
    tco2emis: string;
    tco2compense: string;
}

function ResumeInfoTravel({ index, toggleOpen, openInfoTravel }: { index: number; toggleOpen: (isOpen: boolean) => void; openInfoTravel: boolean }) {
    const onClose = () => {
        toggleOpen(false);
    };

    const [ArrayTravel, setArrayTravel] = useState<Payments[]>([]);
    const [FootPrintIssued, setFootPrintIssued] = useState('');
    const [FootPrintCompensed, setFootPrintCompensed] = useState('');

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        const TableAllTravels = localStorage.getItem('TableAllTravels');
        if (TableAllTravels !== null) {
            const NewTableAllTravels = JSON.parse(TableAllTravels);
            setFootPrintIssued(NewTableAllTravels[index].co2emis);
            
            console.log("ARRAY AT GOOD INDEX", NewTableAllTravels[index]);

            setFootPrintCompensed(NewTableAllTravels[index].co2compense);
            console.log("FOOT PRINT COMPENSED", FootPrintCompensed);
            const FinalArray = NewTableAllTravels[index].payments;
            console.log(JSON.stringify(FinalArray));

            const dataSource = FinalArray.name.map((name: string, index: number) => ({
                key: (index + 1).toString(),
                name: name,
                montant: FinalArray.montant[index],
                contre_valeur_euros: FinalArray.contre_valeur_euros[index],
                devise: FinalArray.devise[index],
                taux: FinalArray.taux[index],
            }));
            console.log("DATASOURCE", dataSource);
            setArrayTravel(dataSource);
        }
    }, []);

    
    const columns = [
        {
            title: 'Beneficiary',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Amount',
            dataIndex: 'montant',
            key: 'montant',
        },
        {
            title: 'Currency',
            dataIndex: 'devise',
            key: 'devise',
        },
        {
            title: 'Rate',
            dataIndex: 'taux',
            key: 'taux',
        },
        {
            title: 'Counter value',
            dataIndex: 'contre_valeur_euros',
            key: 'contre_valeur_euros',
        },
    ];

    const openNotificationWithContent = () => {
        notification.open({
            message: 'Validating your payments',
            description:
                <>
                    <Result
                        status="success"
                        title="Operations carried out"
                        subTitle="Order number: 2017182818828182881"
                        extra={[
                            <Card size="small" title="Your operations" style={{ width: 300, marginLeft: '80px' }}>
                                {ArrayTravel.map((item, key: number) => (
                                    <div key={key}>
                                        <p>{item.name} : {item.montant} {item.devise} </p>
                                    </div>
                                ))}
                            </Card>,
                            <Card style={{ width: 300, marginTop: '10px', marginLeft: '80px' }}>
                                <p>The carbon footprint of your international operation</p>
                                <h2>{FootPrintIssued} T Co2e</h2>
                            </Card>,
                            <Card style={{ width: 300, marginTop: '10px', marginLeft: '80px' }}>
                                <p>Automatic compensation with Keewe</p>
                                <h2>{FootPrintCompensed} T Co2e</h2>
                            </Card>,
                        ]}
                        style={{
                            backgroundColor: 'lightblue',
                            width: '500px',
                            border: '2px solid',
                            borderRadius: '8px',
                            padding: '16px',
                        }}
                    >


                    </Result>

                </>,
            duration: 10,
            className: 'custom-class',
            style: {
                width: 600,
            },
        });
    };

    return (
        <>

            <Drawer width="736" title={<h1>My trip</h1>} placement="right" onClose={onClose} open={openInfoTravel}>
                <Table dataSource={ArrayTravel} columns={columns} pagination={false} />
                <Button type="primary" onClick={openNotificationWithContent}>
                    Making payments
                </Button>

                <Modal
                    open={open}
                    title={<h1>Your operations</h1>}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={550}
                    style={{ top: 20 }}
                    footer={[
                        <Button key="back" type="primary" onClick={onClose}>
                            Back to menu
                        </Button>,
                    ]}
                >

                </Modal>
            </Drawer>
        </>
    )
}

export default ResumeInfoTravel;
