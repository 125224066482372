import React, { useState, ChangeEvent, useEffect } from 'react';
import { CloseOutlined, AppstoreOutlined, BarsOutlined, InboxOutlined } from '@ant-design/icons';
import { Select, SelectProps, Spin, Segmented, Input, Table, Radio } from 'antd';
import type { UploadProps } from 'antd';
import { message, Upload, Button } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faCarBattery, faMotorcycle, faPlane, faSailboat, faTrain } from '@fortawesome/free-solid-svg-icons';

const { Option } = Select;
const { Column } = Table;
const { Dragger } = Upload;

interface CityData {
  key: string;
  name: string;
  longitude: string[];
  latitude: string[];
  cities: string[];
}

interface Transport {
  method: string;
  start: string;
  end: string;
  id_payment: string;
}

interface RefreshInterface {
  Refresh: number;
  RefreshActualize: () => void;
  calculTransport: number;
  setCalculTransport: (value: number) => void;
}

const FormTransport = ({ Refresh, RefreshActualize, calculTransport, setCalculTransport }: RefreshInterface) => {
  const GetAllCitiesFromInput = async (nameInput: string) => {
    const requestData = {
      nameInput: nameInput
    };
    try {
      const response = await axios.get<any[]>('https://backiftm.azurewebsites.net//trip/GetLocationFromInput', {
        params: requestData,
      });
      console.log("THE RESPONSE", response.data);
      return response.data.map(item => ({
        name: item.name,
        cities: item.cities,
        longitude: item.location?.lng,
        latitude: item.location?.lat,
      }));
    } catch (error) {
      console.error('Error sending data to the backend:', error);
      return [];
    }
  };

  const [data, setData] = useState<CityData[]>([
    { key: '1', name: '', cities: [], longitude: [], latitude: [] },
    { key: '2', name: '', cities: [], longitude: [], latitude: [] }
  ]);

  const [selectedValues, setSelectedValues] = useState<string[][]>([
    [''],
    [''],
  ]);

  const [selectedTransport, setSelectedTransport] = useState<string[]>(['']);

  const GetAllDistance = async (AllValueLocation: string[][], AllValueTypeTransport: string[]) => {
    const requestData = {
      AllValueLocation: AllValueLocation,
      AllValueTypeTransport: AllValueTypeTransport
    }
    console.log("ALL VALUE LOCATION", AllValueLocation);
    console.log("ALL VALUE TRANSPORT", AllValueTypeTransport);
    try {
      const response = await axios.get<any[]>('https://backiftm.azurewebsites.net//trip/GetDistanceBetweenTwoPoints', {
        params: requestData,
      });
      localStorage.setItem('FormTransportDistance', JSON.stringify(response.data));
      console.log("DISTANCE", response.data)
      RefreshActualize();
    } catch (error) {
      console.error('Error sending data to the back:', error);
      return [];
    }
  }

  const handleSelectChange = (value: string, rowIndex: number) => {
    const newSelectedValues = [...selectedValues];

    console.log("THE VALUE", newSelectedValues);
    if (newSelectedValues[rowIndex]) {
      newSelectedValues[rowIndex][0] = value;
      setSelectedValues(newSelectedValues);

      const cityIndex = data[rowIndex]?.cities.findIndex((city) => city === value);

      if (cityIndex !== -1 && data[rowIndex]) {
        newSelectedValues[rowIndex][1] = data[rowIndex].longitude[cityIndex];
        newSelectedValues[rowIndex][2] = data[rowIndex].latitude[cityIndex];
        GetAllDistance(selectedValues, selectedTransport);
      } else {
        console.log(`City not found for ${value}`);
      }
    } else {
      console.log(`Selected values at index ${rowIndex} is undefined.`);
    }
  };

  const handleSearch = async (value: string, index: number) => {
    const cityNames = await GetAllCitiesFromInput(value);
    if (cityNames && cityNames.length > 2) {
      const newData = [...data];
      newData[index] = {
        ...newData[index],
        name: value,
        cities: cityNames.map(city => city.name),
        longitude: cityNames.map(city => city.longitude),
        latitude: cityNames.map(city => city.latitude),
      };
      setData(newData);
    }
  };

  const [inputCount, setInputCount] = useState(2);

  const addSelectInput = (count: number, type: string) => {
    if (type === "multiple") {

      for (let i = 0; i < count; ++i) {
        setInputCount((prevInput) => prevInput + 1);
        setData((prevData) => [
          ...prevData,
          {
            key: `${inputCount + 1}`,
            name: '',
            cities: [],
            longitude: [],
            latitude: [],
          },
        ]);

        setSelectedValues((prevSelectedValues) => [...prevSelectedValues, ['']]);
        setSelectedTransport((prevSelectedTransport) => [...prevSelectedTransport, '']);
      }
    }

    else {
      const newData = [...data];
      for (let i = 0; i < count; ++i) {
        setInputCount((prevInput) => prevInput + 1);

        newData.splice(count + i, 0, {
          key: `${inputCount + 1}`,
          name: '',
          cities: [],
          longitude: [],
          latitude: [],
        });

        setData(newData);

        const newSelectedValues = [...selectedValues];
        const newSelectedTransport = [...selectedTransport];

        newSelectedValues.splice(count + i, 0, ['']);
        newSelectedTransport.splice(count + i, 0, '');

        setSelectedValues(newSelectedValues);
        setSelectedTransport(newSelectedTransport);
      }
    }
  };

  const removeSelectInput = (index: number) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);

    const updatedTransport = [...selectedTransport];
    updatedTransport.splice(index, 1);
    setSelectedTransport(updatedTransport);
    const newSelectedValues = [...selectedValues];
    newSelectedValues.splice(index, 1);
    setSelectedValues(newSelectedValues);
    setInputCount(inputCount - 1);
  };

  const handleTransportChange = (value: string, index: number) => {
    const updatedTransport = [...selectedTransport];
    updatedTransport[index] = value;
    setSelectedTransport(updatedTransport);
    localStorage.setItem('FormTransportMethod', JSON.stringify(updatedTransport));
    RefreshActualize();
    console.log()
  };

  const [DrawTransportInformations, setDrawTransportInformations] = useState(false);

  const GetAllCitiesFromInputModified = async (nameInput: string) => {
    const cityNames = await GetAllCitiesFromInput(nameInput);
    const cityInfos = [cityNames[0].name, cityNames[0].longitude, cityNames[0].latitude];
    return cityInfos;
  }

  const DragFinish = async () => {

    const formTransport = JSON.parse(localStorage.getItem('FormTransport') || '{}');
    if (formTransport !== null) {
      const ObjectAtIndex0 = formTransport.transport[0];
      console.log("OBJECT", ObjectAtIndex0);
      if (ObjectAtIndex0) {
        const { start, steps, end } = ObjectAtIndex0;

        console.log("START", start);
        console.log("STEPS", steps);
        console.log("END", end);

        const selected = [
          [start],
          ...(steps as string[]).map((step: string, index) => [step]),
          [end],
        ];

        const startCities = await GetAllCitiesFromInputModified(start);
        const stepsCities = await Promise.all(steps.map((step: string) => GetAllCitiesFromInputModified(step)));
        const endCities = await GetAllCitiesFromInputModified(end);

        selected[0] = [start, ...startCities];
        selected[selected.length - 1] = [end, ...endCities];

        for (let i = 1; i < selected.length - 1; i++) {
          selected[i] = [selected[i][0], ...stepsCities[i - 1]];
        }

        selected[0].shift();

        selected[selected.length - 1].shift();

        for (let i = 1; i < selected.length - 1; i++) {
          selected[i].shift();
        }

        const sizeSelected = selected.length - 2;
        addSelectInput(sizeSelected, "multiple");
        console.log("SELECTEEDD", selected);
        setSelectedValues(selected);
        console.log("TRANSPORT", formTransport);
        const selectedTransportFromFile = formTransport.transport[0].method;
        setSelectedTransport(selectedTransportFromFile);

        localStorage.setItem('FormTransportMethod', JSON.stringify(selectedTransportFromFile));
        console.log(selectedTransportFromFile);
        GetAllDistance(selected, [selectedTransportFromFile]);
      }
    }
  }

  const [FirstEnterOnTransport, setFirstEnterOnTransport] = useState(false);

  useEffect(() => {
    console.log("STATE ENTER TRANSPORT", FirstEnterOnTransport);
    console.log("CALCUL TRANSPORT", calculTransport);
    if (FirstEnterOnTransport === false && calculTransport === 0) {
      console.log("STATE ENTER OK");
      DragFinish();
      setCalculTransport(-1);
      setFirstEnterOnTransport(true);
    }
    else if (FirstEnterOnTransport === false && calculTransport === 1) {
      setDrawTransportInformations(true);
      DragFinish();
      setFirstEnterOnTransport(true);
    }
  }, [Refresh, calculTransport]);

  return (
    <>
      {DrawTransportInformations && calculTransport === 1 && (
        <>
          <h1>My Transport</h1>
          {Array.from({ length: inputCount }).map((_, rowIndex) => (
            <div key={rowIndex}>
              {Array.from({ length: 1 }).map((_, colIndex) => (
                <>
                  {rowIndex === 0 ? (
                    <h3>Departure</h3>
                  ) : rowIndex + 1 === inputCount ? (
                    <h3><br />Arrival</h3>
                  ) : (
                    <h3><br />Steps</h3>
                  )}
                  <Select
                    key={colIndex}
                    style={{ width: '100%' }}
                    value={selectedValues[rowIndex]?.[colIndex] || ''}
                    onChange={(value) => handleSelectChange(value, rowIndex)}
                    showSearch
                    optionFilterProp="children"
                    onSearch={(value) => handleSearch(value, rowIndex)}
                  >
                    {data[rowIndex].cities.map((city, cityIndex) => (
                      <Option key={cityIndex} value={city}>
                        {city}
                      </Option>
                    ))}
                  </Select>
                </>
              ))}
              {rowIndex + 1 !== inputCount && (
                <>
                  <div style={{ marginTop: '10px' }}>
                    <Radio.Group
                      onChange={(e) => handleTransportChange(e.target.value, rowIndex)}
                      value={selectedTransport[rowIndex]}
                    >
                      <Radio.Button value="Car">
                        <FontAwesomeIcon icon={faCar} className="fa-2x" />
                      </Radio.Button>
                      <Radio.Button value="Plane">
                        <FontAwesomeIcon icon={faPlane} className="fa-2x" />
                      </Radio.Button>
                      <Radio.Button value="Boat">
                        <FontAwesomeIcon icon={faSailboat} className="fa-2x" />
                      </Radio.Button>
                      <Radio.Button value="Train">
                        <FontAwesomeIcon icon={faTrain} className="fa-2x" />
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    {inputCount > 1 && (
                      <>
                        <Button onClick={() => addSelectInput(rowIndex + 1, "single")}>+</Button>
                      </>
                    )}
                    {inputCount > 2 && rowIndex > 0 && rowIndex + 1 != inputCount && (
                      <Button onClick={() => removeSelectInput(rowIndex)}>-</Button>
                    )}
                  </div>

                  {/*
                  Pour assigner 0 de base à l'id de payment si cela ne vient pas de l'id payment
                  <Form.Item noStyle name={[field.name, "id_payment"]} initialValue={form.getFieldValue([field.name, "id_payment"]) || 0}>
                  </Form.Item>

                */}
                </>
              )}
            </div>
          ))}
        </>
      )}

    </>
  );
};

const draggerStyle = {
  width: '500px',
};

export default FormTransport;
