import { Modal, Form, Input, Button, Segmented, Select, Space, Card, Row, Col, DatePicker, Checkbox, Radio, Divider } from 'antd';
import locale from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
import React, { useState, MouseEventHandler, useEffect } from 'react';
import styled from 'styled-components';
import {
    DownloadOutlined,
    EllipsisOutlined,
    HeartOutlined,
    LikeOutlined,
    CommentOutlined,
    StarOutlined,
    ShareAltOutlined,
    WarningOutlined,
    MailOutlined,
    MobileOutlined,
} from '@ant-design/icons';
import { AccomodationCountry } from '../atoms/AccomodationCountry';
import { AllDevise } from '../atoms/AllDevise';
import FormItem from 'antd/es/form/FormItem';
import { RandomId } from '../atoms/RandomId';

const { Option } = Select;

interface CreateOrAddBeneficiaryProps {
    visible: boolean;
    onClose: () => void;
    setIndexOperation: (newValue: number) => void;
    setInitialValueFormCreationBeneficiary: (newValue: {}) => void;
    RefreshActualize: () => void;
    index: number;
    Refresh: number;
    initialValue: {};
    arrayAllFormCategories: Category[];
    defaultValue: string[];
    setArrayAllCategories: (newValue: Category[]) => void;
    setDefaultValue: (newValue: string[]) => void;
    idPayment: string;
}

interface Category {
    method: string;
    country?: string;
    nb_of_nights?: string;
    id_payment: string;
    nb_half_day?: string;
    nb_of_meals?: string;
    category?: string;
    index?: string;
    start?: string;
    end?: string;
}

function CreateOrAddBeneficiaryorPayment({ visible, onClose, index, initialValue, setIndexOperation, setInitialValueFormCreationBeneficiary, RefreshActualize, Refresh, arrayAllFormCategories, defaultValue, setArrayAllCategories, setDefaultValue, idPayment }: CreateOrAddBeneficiaryProps) {
    const [form] = Form.useForm();

    const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

    const handleChange = (value: string[], option: React.ReactElement<any, string> | React.ReactElement<any, string>[]) => {
        //const selectedIndexes = Array.isArray(option) ? option.map((opt) => parseInt(opt.key as string, 10)) : [parseInt(option.key as string, 10)];
        //console.log(arrayAllFormCategories);
        const newSelectedIndexes = Array.isArray(option) ? option.map((opt) => parseInt(opt.key as string, 10)) : [parseInt(option.key as string, 10)];

        setSelectedIndexes(newSelectedIndexes);

        console.log('Selected values and their indexes:', value, selectedIndexes);
    };

    const AddFormPayment = (index: number, ObjectPayment: any) => {
        let newArrayFormPayment: any = {};

        for (let j = 0; j < selectedIndexes.length; ++j) {
            arrayAllFormCategories[selectedIndexes[j]].id_payment = idPayment;
        }

        const ArrayAccomodation = [];
        const ArrayActivities = [];
        const ArrayFood = [];
        const ArrayTransport = [];

        for (const item of arrayAllFormCategories) {
            const newItem = { ...item };
            delete newItem.category;
            delete newItem.index;

            if (item.category === "transport") {
                ArrayTransport.push(newItem);
            } else if (item.category === "accomodation") {
                ArrayAccomodation.push(newItem);
            } else if (item.category === "activities") {
                ArrayActivities.push(newItem);
            } else if (item.category === "food") {
                ArrayFood.push(newItem);
            }
        }

        const ArrayTransportFinal = {
            transport: ArrayTransport
        }

        const ArrayAccomodationFinal = {
            accomodation: ArrayAccomodation
        }

        const ArrayActivitiesFinal = {
            activities: ArrayActivities
        }

        const ArrayFoodFinal = {
            food: ArrayFood
        }

        localStorage.setItem('FormTransport', JSON.stringify(ArrayTransportFinal));
        localStorage.setItem('FormAccomodation', JSON.stringify(ArrayAccomodationFinal));
        localStorage.setItem('FormActivities', JSON.stringify(ArrayActivitiesFinal));
        localStorage.setItem('FormFood', JSON.stringify(ArrayFoodFinal));

        const ArrayFormAddPayment = localStorage.getItem('FormPayment');
        if (ArrayFormAddPayment !== null) {
            try {
                newArrayFormPayment = JSON.parse(ArrayFormAddPayment);
                console.log("START", newArrayFormPayment);

                if (!Array.isArray(newArrayFormPayment.payment)) {
                    newArrayFormPayment.payment = [];
                }

                if (index === -1) {
                    console.log("BEFORE PUSH", newArrayFormPayment.payment);
                    ObjectPayment.name = ObjectPayment.name === "" ? "" : ObjectPayment.name;
                    ObjectPayment.montant = ObjectPayment.montant === "" ? "" : ObjectPayment.montant;
                    ObjectPayment.beneficiaryNickname = ObjectPayment.beneficiaryNickname === "" ? "" : ObjectPayment.beneficiaryNickname;
                    ObjectPayment.countryBeneficiary = ObjectPayment.countryBeneficiary === "" ? "" : ObjectPayment.countryBeneficiary;
                    ObjectPayment.currencyAccount = ObjectPayment.currencyAccount === "" ? "" : ObjectPayment.currencyAccount;
                    ObjectPayment.countryBankAccount = ObjectPayment.countryBankAccount === "" ? "" : ObjectPayment.countryBankAccount;
                    ObjectPayment.bicSwift = ObjectPayment.bicSwift === "" ? "" : ObjectPayment.bicSwift;
                    ObjectPayment.iban = ObjectPayment.iban === "" ? "" : ObjectPayment.iban;
                    ObjectPayment.debit = ObjectPayment.debit === "" ? "" : ObjectPayment.debit;
                    ObjectPayment.description = ObjectPayment.description === "" ? "" : ObjectPayment.description;
                    ObjectPayment.devise = ObjectPayment.devise === "" ? "" : ObjectPayment.devise;
                    ObjectPayment.tco2emis = ObjectPayment.tco2emis === "" ? "" : ObjectPayment.tco2emis;
                    ObjectPayment.tco2emis_transport = ObjectPayment.tco2emis_transport === "" ? "" : ObjectPayment.tco2emis_transport;
                    ObjectPayment.tco2emis_accomodation = ObjectPayment.tco2emis_accomodation === "" ? "" : ObjectPayment.tco2emis_accomodation;
                    ObjectPayment.tco2emis_activities = ObjectPayment.tco2emis_activities === "" ? "" : ObjectPayment.tco2emis_activities;
                    ObjectPayment.tco2emis_food = ObjectPayment.tco2emis_food === "" ? "" : ObjectPayment.tco2emis_food;

                    newArrayFormPayment.payment.push(ObjectPayment);
                    console.log("AFTER PUSH", newArrayFormPayment.payment);
                } else {
                    console.log("ENTER HERE", ObjectPayment);
                    ObjectPayment.name = ObjectPayment.name === "" ? "" : ObjectPayment.name;
                    ObjectPayment.montant = ObjectPayment.montant === "" ? "" : ObjectPayment.montant;
                    ObjectPayment.beneficiaryNickname = ObjectPayment.beneficiaryNickname === "" ? "" : ObjectPayment.beneficiaryNickname;
                    ObjectPayment.countryBeneficiary = ObjectPayment.countryBeneficiary === "" ? "" : ObjectPayment.countryBeneficiary;
                    ObjectPayment.currencyAccount = ObjectPayment.currencyAccount === "" ? "" : ObjectPayment.currencyAccount;
                    ObjectPayment.countryBankAccount = ObjectPayment.countryBankAccount === "" ? "" : ObjectPayment.countryBankAccount;
                    ObjectPayment.bicSwift = ObjectPayment.bicSwift === "" ? "" : ObjectPayment.bicSwift;
                    ObjectPayment.iban = ObjectPayment.iban === "" ? "" : ObjectPayment.iban;
                    ObjectPayment.debit = ObjectPayment.debit === "" ? "" : ObjectPayment.debit;
                    ObjectPayment.description = ObjectPayment.description === "" ? "" : ObjectPayment.description;
                    ObjectPayment.devise = ObjectPayment.devise === "" ? "" : ObjectPayment.devise;
                    ObjectPayment.tco2emis = ObjectPayment.tco2emis === "" ? "" : ObjectPayment.tco2emis;
                    ObjectPayment.tco2emis_transport = ObjectPayment.tco2emis_transport === "" ? "" : ObjectPayment.tco2emis_transport;
                    ObjectPayment.tco2emis_accomodation = ObjectPayment.tco2emis_accomodation === "" ? "" : ObjectPayment.tco2emis_accomodation;
                    ObjectPayment.tco2emis_activities = ObjectPayment.tco2emis_activities === "" ? "" : ObjectPayment.tco2emis_activities;
                    ObjectPayment.tco2emis_food = ObjectPayment.tco2emis_food === "" ? "" : ObjectPayment.tco2emis_food;

                    //const montant = Number(ObjectPayment.montant);
                    //const marginPrice = (1 / 1.0547) * montant;
                    //const KeeweMargin = montant - marginPrice;
                    //const cashBack = KeeweMargin * 0.15;
                    //const amountCompensed = cashBack / 5;
                    //ObjectPayment.tco2compense = amountCompensed.toFixed(2).toString();
//
                    //ObjectPayment.contre_valeur_euros = (Number(montant) * 0.94).toFixed(2).toString();

                    //console.log("NEW ARRAY", newArrayFormPayment.payment[index]);
                    //newArrayFormPayment.payment[index] = ObjectPayment;
                    //const firstObject = newArrayFormPayment.payment[index];
                    
                    const premierObject = newArrayFormPayment.payment[index];
                    const deuxiemeObject = ObjectPayment;
                    console.log("VRAIMENT AVANT", premierObject);
                    console.log("DONT UNDERSTAND", deuxiemeObject);
                    for (const key in deuxiemeObject) {
                        if (deuxiemeObject[key] !== premierObject[key]) {
                            deuxiemeObject[key] = premierObject[key];
                        }
                    }
                    console.log("ARRAY FINAL", deuxiemeObject);

                    //console.log("VRAIMENT APRES", deuxiemeObject);
                    RefreshActualize();
                    //newArrayFormPayment.payment[index] = ObjectPayment;
                }

                localStorage.setItem('FormPayment', JSON.stringify(newArrayFormPayment));
                console.log("FormPayment", newArrayFormPayment);
                RefreshActualize();
            } catch (error) {
                console.error("Error parsing stored data:", error);
            }
        }
    }

    /*const PushInAllCategoriesAccomodation = (ArrayAllCategories: any[], FormType: string, Type: string) => {
        const ArrayString = localStorage.getItem(FormType);
        if (ArrayString !== null) {
            try {
                const NewArrayAccommodation = JSON.parse(ArrayString);
                if (Array.isArray(NewArrayAccommodation[Type])) {
                    NewArrayAccommodation[Type].forEach((item: any) => {
                        if (typeof item === 'object' && item !== null) {
                            const newItem = { ...item };
                            newItem.category = Type;

                            ArrayAllCategories.push(newItem);
                        }
                    });
                } else {
                    console.error(`Data in ${FormType}.${Type} is not an array.`);
                }
            } catch (error) {
                console.error(`Error parsing data in ${FormType}: ${error}`);
            }
        }
        return ArrayAllCategories;
    }


    const [arrayAllFormCategories, setArrayAllCategories] = useState<Category[]>([]);
    const [defaultValue, setDefaultValue] = useState<string[]>([]);

    useEffect(() => {
        let ArrayAllCategories: any[] = [];
        ArrayAllCategories = PushInAllCategoriesAccomodation(ArrayAllCategories, "FormAccomodation", "accomodation");
        ArrayAllCategories = PushInAllCategoriesAccomodation(ArrayAllCategories, "FormActivities", "activities");
        ArrayAllCategories = PushInAllCategoriesAccomodation(ArrayAllCategories, "FormFood", "food");
        console.log("ICI CATEGORIES", ArrayAllCategories);
        setArrayAllCategories(ArrayAllCategories);

        const defaultValueForm: string[] = ArrayAllCategories
        .filter(item => item.id_payment === "1")
        .map(item => {
            let valueToDisplay = "";
            if (item.category === "accomodation") {
                valueToDisplay = `${item.method} en ${item.country} pour ${item.nb_of_nights} nuits`;
            } else if (item.category === "activities") {
                valueToDisplay = `${item.method} pour ${item.nb_half_day} demi-journées`;
            } else if (item.category === "food") {
                valueToDisplay = `${item.method} pour ${item.nb_of_meals} repas`;
            }
            return valueToDisplay;
        });
        console.log("DEFAULT", defaultValueForm);
        setDefaultValue(defaultValueForm);
    }, [Refresh]);*/

    const onFinish = (ObjectPayment: any) => {
        console.log('Form submitted with values:', ObjectPayment);

        AddFormPayment(index, ObjectPayment);
        setIndexOperation(-1);
        setInitialValueFormCreationBeneficiary({});
        setArrayAllCategories([]);
        setDefaultValue([]);
        onClose();
    };

    const outOfModal = () => {
        console.log("OUT")
        setIndexOperation(-1);
        setInitialValueFormCreationBeneficiary({});
        setArrayAllCategories([]);
        setDefaultValue([]);
        onClose();
    }

    const [componentDisabled, setComponentDisabled] = useState(true);
    console.log("INITIALVALUE", initialValue, index);
    return (
        <>
            <Modal
                title={<h1>Add a payment</h1>}
                width={1000}
                style={{ top: 20 }}
                visible={visible}
                onCancel={outOfModal}
                footer={[]}
            >
                <Form
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    style={{ maxWidth: 600 }}
                    labelCol={{ span: 18 }}
                    labelAlign="left"
                    layout="vertical"
                    wrapperCol={{ span: 16 }}
                    initialValues={initialValue}
                >
                    <Card title={<h2>Beneficiary</h2>} style={{ width: 950 }}>

                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    label="Beneficiary account name"
                                    name="name"
                                    rules={[{ required: true, message: 'Nom du compte du bénéficiaire est requis' }]}
                                    required tooltip="Ce nom correspond au nom du compte présent sur votre facture"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Nickname of beneficiary"
                                    name="beneficiaryNickname"
                                    tooltip="Surnom du compte tel qu'il apparait dans la plateforme"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Beneficiary's country"
                                    name="countryBeneficiary"
                                >
                                    <Select
                                        placeholder="Select a method"
                                        dropdownStyle={{ width: '300px' }}
                                        style={{ marginRight: '8px' }}
                                    >
                                        {AccomodationCountry.map((option, index) => (
                                            <Option key={index} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Account currency"
                                    name="currencyAccount"
                                >
                                    <Select
                                        placeholder="Select a method"
                                        dropdownStyle={{ width: '300px' }}
                                        style={{ marginRight: '8px' }}
                                    >
                                        {AllDevise.map((option, index) => (
                                            <Option key={index} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Country of bank account"
                                    name="countryBankAccount"
                                >
                                    <Select
                                        placeholder="Select a method"
                                        dropdownStyle={{ width: '300px' }}
                                        style={{ marginRight: '8px' }}
                                    >
                                        {AccomodationCountry.map((option, index) => (
                                            <Option key={index} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>

                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="IBAN"
                                    name="iban"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="BIC / SWIFT"
                                    name="bicSwift"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Divider />
                    <Card title={<h2>Opération</h2>} style={{ width: 950 }}>
                        <Form.Item label="You charge" style={{ marginBottom: 0 }}>
                            <Form.Item
                                name={['debit']}
                                style={{ width: 'calc(50% - 8px)' }}
                                help="Amount available : 74 827.75 USD"
                            >
                                <Select
                                    placeholder="Select a method"
                                    dropdownStyle={{ width: '300px' }}
                                    style={{ marginRight: '8px' }}
                                >
                                    {AllDevise.map((option, index) => (
                                        <Option key={index} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item label="Currency and amount" style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="devise"
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="montant"
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                            >
                                <Input />
                            </Form.Item>
                        </Form.Item>

                        <Form
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal"
                            disabled={componentDisabled}
                            style={{ maxWidth: 600 }}
                        >

                            <Form.Item label="Transfer fees">
                                <Input placeholder='0 EUR' />
                            </Form.Item>
                        </Form>
                        <br />
                        <Form.Item
                            name="description"
                            label="Description"
                        >
                            <Input placeholder='Invoice number' />
                        </Form.Item>

                        <Form.Item
                            name="categoriesSelectionned"
                            label="Choose your travel details">
                            <Select
                                defaultValue={defaultValue}
                                mode="multiple"
                                placeholder="Select parts of your journey"
                                onChange={handleChange}
                            >
                                {arrayAllFormCategories.map((item, index) => {
                                    let valueToDisplay;
                                    if (item.category === "transport") {
                                        valueToDisplay = `Journey by ${item.method}. Departure : ${item.start}. Arrival : ${item.end}`
                                    } else if (item.category === "accomodation") {
                                        valueToDisplay = `${item.method} located at ${item.country} for ${item.nb_of_nights} nights.`
                                    } else if (item.category === "activities") {
                                        valueToDisplay = `${item.method} for ${item.nb_half_day} half-days.`;
                                    } else if (item.category === "food") {
                                        valueToDisplay = `${item.method} for ${item.nb_of_meals} meal.`;
                                    }

                                    return (
                                        <Option key={index} value={valueToDisplay}>
                                            {valueToDisplay}
                                        </Option>
                                    );
                                })}

                            </Select>
                        </Form.Item>
                    </Card>

                    <Form.Item>
                        <Button htmlType="button" onClick={outOfModal}>
                            Back
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Register payment
                        </Button>
                    </Form.Item>

                </Form>
            </Modal >
        </>
    )
}

const formContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
};

const formStyle = {
    width: '400px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
};

const headingStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#1890ff',
};

export default CreateOrAddBeneficiaryorPayment;


/*

2) Validation du formulaire :

    function ValidateAddPaymentForm = () => {

    }

    function ValidateModifyPaymentForm = () => {
        
    }

    function ValidateAddPaymentForm = (index) => {
        localeStorage
        if (index === -1) {
            
        } else {

        }
    }
LocaleStorage setItem


3) Quand on valide le voyage on met localeStoragesetItem('FormAddPayment', '[]');

*/



