export const AllDevise = [
    "🇲🇫EURO(EUR)",
    "🇺🇸Dollar Américain (USD)",
    "🇬🇧Livre Sterling (GBP)",
    "🇯🇵Yen Japonais (JPY)",
    "🇦🇺Dollar Australien (AUD)",
    "🇳🇿Dollar néozélandais (NZD)",
    "🇨🇦Dollar Canadien (CAD)",
    "🇨🇭Franc Suisse (CHF)",
    "🇳🇴Couronne norvégienne (NOK)",
    "🇸🇪Couronne suédoise (SEK)",
    "🇩🇰Couronne danoise (DKK)",
    "🇭🇰Dollar de Hong-Kong (HKD)",
    "🇸🇬Dollar de Singapour (SGD)",
    "🇭🇺Florint (HUF)",
    "🇷🇴Leu roumain (RON)",
    "🇨🇿Couronne tchèque (CZK)",
    "🇭🇷Kuna (HRK)",
    "🇹🇷Livre turque (TRY)",
    "🇲🇽Peso mexicain (MXN)",
    "🇿🇦Rand (ZAR)",
    "🇵🇱Zloty (PLN)",
    "🇰🇪Shilling Kényan (KES)",
    "🇸🇦Saudi Riyal (SAR)",
    "🇦🇪Dirahm des Emirats arabes (AED)"
]