import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from './components/organisms/Sidebar';
import TravelPage from './components/pages/TravelPage';
import AddTripDrawer from './components/organisms/AddTripDrawer';

function App() {
  const [refresh, setRefresh] = useState(1);

  const refreshActualize = () => {
    const ref = refresh + 1;
    setRefresh(ref);
  };

  const ValueFormTransport = {
    transport: [],
  };

  const ValueFormAccomodation = {
    accomodation: [],
  };

  const ValueFormActivities = {
    activities: [],
  };

  const ValueFormFood = {
    food: [],
  };

  const ValueFormPayment = {
    payment: [],
  };

  useEffect(() => {
      localStorage.setItem('FormTransport', JSON.stringify(ValueFormTransport));
      localStorage.setItem('FormAccomodation', JSON.stringify(ValueFormAccomodation));
      localStorage.setItem('FormActivities', JSON.stringify(ValueFormActivities));
      localStorage.setItem('FormFood', JSON.stringify(ValueFormFood));
      localStorage.setItem('FormPayment', JSON.stringify(ValueFormPayment));
      localStorage.setItem('FormTransportDistance', JSON.stringify("[]"));
      localStorage.setItem('FormTransportMethod', JSON.stringify("[]"));
      localStorage.setItem('FormImageName', '[]');
      localStorage.setItem('NameTravel', '');
      localStorage.setItem('TableAllTravels', '[]');
      refreshActualize();
  }, [])

  return (
    <Container>
      <Sidebar />
      <Layout.Content>
        <TravelPage Refresh={refresh} RefreshActualize={refreshActualize} />
        <AddTripDrawer Refresh={refresh} RefreshActualize={refreshActualize} />
      </Layout.Content>
    </Container>
  );
}

export default App;

const Container = styled(Layout)`
  min-height: 100vh;
  background: white;

  & > .ant-layout-content {
    padding: 36px 24px;
  }
`;
