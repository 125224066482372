import React, { useEffect, useState } from 'react';
import { CloseOutlined, DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { Checkbox, Button, Card, Form, Input, Space, Typography, Select, Table, Modal, notification } from 'antd';
import axios from 'axios';
import { request } from 'http';
import locale from 'antd/es/date-picker/locale/en_US';
import TableInfoTravelPayment from '../atoms/TableInfoTravelPayment';
import CreateOrAddBeneficiary from './CreateOrAddBeneficiary';
import { CSSProperties } from 'react';
import { RandomId } from '../atoms/RandomId';
import PassPayments from './PassPayments';

interface Payments {
  name: string;
  montant: string;
  contre_valeur_euros: string;
  devise: string;
  taux: string;
  tco2emis: string;
  tco2compense: string;
}

interface Category {
  method: string;
  country?: string;
  nb_of_nights?: string;
  id_payment: string;
  nb_half_day?: string;
  nb_of_meals?: string;
  category?: string;
  start?: string;
  end?: string;
}

interface TableInfoTravelPaymentProps {
  data: { key: string; value: string }[];
  TotalPayments: Payments[];
  dataSource: any[];
  onDeletePayment: (paymentIndex: number) => void;
  onEditPayment: (paymentIndex: number) => void;
}

interface RefreshInterface {
  Refresh: number;
  RefreshActualize: () => void;
  onClose: () => void;
}

const CalculationCo2 = ({ Refresh, RefreshActualize, onClose }: RefreshInterface) => {
  const formAccomodation = JSON.parse(localStorage.getItem('FormAccomodation') || '{}');
  const formActivities = JSON.parse(localStorage.getItem('FormActivities') || '{}');
  const formFood = JSON.parse(localStorage.getItem('FormFood') || '{}');
  const formPayment = JSON.parse(localStorage.getItem('FormPayment') || '{}');
  const formTransportDistance = JSON.parse(localStorage.getItem('FormTransportDistance') || '{}');
  const formTransportMethod = JSON.parse(localStorage.getItem('FormTransportMethod') || '{}');
  const formTransport = JSON.parse(localStorage.getItem('FormTransport') || '{}');

  console.log("FINAL 1 ", formTransportDistance);
  console.log("FINAL 2", formTransportMethod);

  const transformFormAccomodation = formAccomodation.accomodation;
  const transformFormActivities = formActivities.activities;
  const transformFormFood = formFood.food;
  const transformFormPayment = formPayment.payment;
  const transformFormTransport = formTransport.transport;

  const [TotalTransport, setTotalTransport] = useState('');
  const [TotalAccomodation, setTotalAccomodation] = useState('');
  const [TotalActivities, setTotalActivities] = useState('');
  const [TotalFood, setTotalFood] = useState('');
  const [TotalCo2Emis, setTotalCo2Emis] = useState('');
  const [TotalPayments, setTotalPayments] = useState<Payments[]>([]);

  const [PopupFinalOpen, setPopupFinalOpen] = useState(false);

  const requestData = {
    FormAccomodation: transformFormAccomodation,
    FormActivities: transformFormActivities,
    FormFood: transformFormFood,
    FormPayment: transformFormPayment,
    FormTransportDistance: formTransportDistance,
    FormTransportMethod: formTransportMethod,
    FormTransport: transformFormTransport
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('https://backiftm.azurewebsites.net//voyage/CalculAllCategories', requestData);
      console.log('Response from the backend:', response.data);
      setTotalAccomodation(response.data.totalAccomodation);
      setTotalActivities(response.data.totalActivities);
      setTotalFood(response.data.totalFood);
      setTotalCo2Emis(response.data.totalCo2Emis);
      setTotalPayments(response.data.FormPayment);
      setTotalTransport(response.data.totalTransport)
      AssignPaymentNameToData(response.data.FormPayment);
    } catch (error) {
      console.error('Error sending data to the backend:', error);
    }
  };

  const TableInfoTravelPayment: React.FC<TableInfoTravelPaymentProps> = ({
    data,
    TotalPayments,
    dataSource,
    onDeletePayment,
    onEditPayment,
  }) => {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        ellipsis: true,
      },
      {
        title: 'Amount',
        dataIndex: 'montant',
        key: 'montant',
      },
      {
        title: 'Currency',
        dataIndex: 'devise',
        key: 'devise',
        width: 95,
      },
      {
        title: 'Rate',
        dataIndex: 'taux',
        key: 'taux',
        width: 64,
      },
      {
        title: 'Counter values',
        dataIndex: 'contre_valeur_euros',
        key: 'contre_valeur_euros',
        width: 100,
      },
      {
        title: 'Co2 Emissions',
        dataIndex: 'tco2emis',
        key: 'tco2emis',
        width: 100
      },

      {
        title: 'Actions',
        key: 'actions',
        render: (_: any, record: any, paymentIndex: number) => (
          <Space>
            <Button
              type="primary"
              icon={<EyeFilled />}
              onClick={() => onEditPayment(paymentIndex)}
            />
            {/*<Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeletePayment(paymentIndex)}
          />*/}
          </Space>
        ),
      },
    ];

    return <Table columns={columns} dataSource={dataSource} pagination={false} />;
  };

  const handlePaymentEngine = () => {
    const tableAllTravelsString = localStorage.getItem('TableAllTravels');
    const travelArray = [];

    const name = TotalPayments.map(payment => payment.name);
    const montant = TotalPayments.map(payment => payment.montant);
    const contre_valeur_euros = TotalPayments.map(payment => payment.contre_valeur_euros);
    const devise = TotalPayments.map(payment => payment.devise);
    const taux = TotalPayments.map(payment => payment.taux);

    const NameTravel = localStorage.getItem('NameTravel');
    let NewNameTravel = ""
    if (NameTravel !== null) {
      NewNameTravel = NameTravel;
    }

    const storedData = localStorage.getItem('FormPayment');
    const AllPayment = storedData ? JSON.parse(storedData) : {};

    console.log("PLEASE PAYMENT", AllPayment);
    let FootPrintCompensed = 0;
    for (let i = 0; i < AllPayment.payment.length; ++i) {
      FootPrintCompensed += Number(AllPayment.payment[i].tco2compense);
      console.log(AllPayment.payment[i]);
    }

    console.log("THE FOOT PRINT", FootPrintCompensed);

    const NewTravel = {
      intitulé: NewNameTravel,
      co2emis: TotalCo2Emis,
      co2compense: FootPrintCompensed.toString(),
      co2emistransport: "0",
      co2emisaccomodation: TotalAccomodation,
      co2emisactivities: TotalActivities,
      co2emisfood: TotalFood,
      payments: {
        name: name,
        montant: montant,
        contre_valeur_euros: contre_valeur_euros,
        devise: devise,
        taux: taux,
      }
    };
    console.log("NEW FINAL LLLL ", NewTravel);
    if (tableAllTravelsString !== null) {
      const Newarray = JSON.parse(tableAllTravelsString);
      if (Newarray.length > 0) {
        Newarray.push(NewTravel);
        localStorage.setItem('TableAllTravels', JSON.stringify(Newarray));
      } else {
        travelArray.push(NewTravel);
        localStorage.setItem('TableAllTravels', JSON.stringify(travelArray));
      }
    } else {
      travelArray.push(NewTravel);
      localStorage.setItem('TableAllTravels', JSON.stringify(travelArray));
    }
    onClose();
    setPopupFinalOpen(false);
  };

  const handleCancel = () => {
    setPopupFinalOpen(false);
  }

  const [BeneficiaryPopupState, setBeneficiaryPopupState] = useState(false);
  const [indexOperation, setIndexOperation] = useState(-1);

  const data: { key: string; value: string }[] = [];

  const [FillInfoTable, setFillInfoTable] = useState(false);
  const AssignPaymentNameToData = (TotalPayments: Payments[]) => {
    for (let i = 0; i < TotalPayments.length; i++) {
      data.push({
        key: TotalPayments[i].name,
        value: TotalPayments[i].name,
      });
    }
    setFillInfoTable(true);
    console.log("DATA", data);
  };

  useEffect(() => {
    const arrayToUpdate = localStorage.getItem('FormPayment');
    if (arrayToUpdate !== null) {
      const newArrayToUpdate = JSON.parse(arrayToUpdate);
      setTotalPayments(newArrayToUpdate.payment);
    }

  }, [Refresh]);

  const dataSource = TotalPayments.map((item, index) => ({
    key: `${index + 1}`,
    name: item.name,
    montant: item.montant,
    contre_valeur_euros: item.contre_valeur_euros,
    devise: item.devise,
    taux: item.taux,
    tco2emis: item.tco2emis,
    tco2compense: item.tco2compense
  }));

  const closeBeneficiaryPopup = () => {
    setBeneficiaryPopupState(false);
    setIndexOperation(-1);
  };

  const handleDeletePayment = (paymentIndex: number) => {
    const updatedTotalPayments = [...TotalPayments];
    updatedTotalPayments.splice(paymentIndex, 1);
    setTotalPayments(updatedTotalPayments);

    const ValueFormPayment = {
      payment: updatedTotalPayments,
    };
    console.log("NEW VALUE AFTER DELETE", ValueFormPayment);
    localStorage.setItem('FormPayment', JSON.stringify(ValueFormPayment));
  };

  const [initialValueFormCreationBeneficiary, setInitialValueFormCreationBeneficiary] = useState({});

  useEffect(() => {
    handleSubmit();
  }, [Refresh]);

  const [arrayAllFormCategories, setArrayAllCategories] = useState<Category[]>([]);
  const [defaultValue, setDefaultValue] = useState<string[]>([]);

  const [idPayment, setIdPayment] = useState('');

  const handleEditPayment = (paymentIndex: number, index: number) => {
    let idPaymentInstant = "";

    setIndexOperation(paymentIndex);
    if (paymentIndex !== -1) {
      const ArrayFormAddPayment = localStorage.getItem('FormPayment');
      if (ArrayFormAddPayment !== null) {
        const newArrayFormPayment = JSON.parse(ArrayFormAddPayment);
        const ArrayAtSelectionnedIndex = newArrayFormPayment.payment[paymentIndex];
        setIdPayment(ArrayAtSelectionnedIndex.id_payment);
        idPaymentInstant = ArrayAtSelectionnedIndex.id_payment;
        setInitialValueFormCreationBeneficiary(ArrayAtSelectionnedIndex);
      }
    } else {
      setIdPayment(RandomId());
      idPaymentInstant = RandomId();
      setInitialValueFormCreationBeneficiary({});
    }

    /* DEFAULT VALUE */

    const PushInAllCategoriesAccomodation = (ArrayAllCategories: any[], FormType: string, Type: string) => {

      const ArrayString = localStorage.getItem(FormType);
      if (ArrayString !== null) {
        try {
          const NewArrayAccommodation = JSON.parse(ArrayString);
          if (Array.isArray(NewArrayAccommodation[Type])) {
            let i = 0;
            NewArrayAccommodation[Type].forEach((item: any) => {
              if (typeof item === 'object' && item !== null) {
                const newItem = { ...item };
                newItem.category = Type;
                newItem.index = i;
                ArrayAllCategories.push(newItem);
              }
              ++i;
            });
            console.log("THE ARRAY INSANE", ArrayAllCategories);
          } else {
            console.error(`Data in ${FormType}.${Type} is not an array.`);
          }
        } catch (error) {
          console.error(`Error parsing data in ${FormType}: ${error}`);
        }
      }
      return ArrayAllCategories;
    }

    // Find array payment with index
    // Find id_payment
    // Remplacer .filter(item => item.id_payment === "1") par id_payment correspondant

    let ArrayAllCategories: any[] = [];
    ArrayAllCategories = PushInAllCategoriesAccomodation(ArrayAllCategories, "FormTransport", "transport");
    ArrayAllCategories = PushInAllCategoriesAccomodation(ArrayAllCategories, "FormAccomodation", "accomodation");
    ArrayAllCategories = PushInAllCategoriesAccomodation(ArrayAllCategories, "FormActivities", "activities");
    ArrayAllCategories = PushInAllCategoriesAccomodation(ArrayAllCategories, "FormFood", "food");
    console.log("ICI CATEGORIES", ArrayAllCategories);
    setArrayAllCategories(ArrayAllCategories);
    console.log("IDPAYMENT", idPayment);
    let i = 0;
    const defaultValueForm: string[] = ArrayAllCategories
      .filter(item => item.id_payment === idPaymentInstant)
      .map(item => {
        let valueToDisplay = "";
        console.log("OUR ID_PAYMENT", idPayment);
        if (item.category === "transport") {
          valueToDisplay = `Journey by ${item.method}. Departure : ${item.start}. Arrival : ${item.end}`
        } else if (item.category === "accomodation") {
          valueToDisplay = `${item.method} located at ${item.country} for ${item.nb_of_nights} nights.`
        } else if (item.category === "activities") {
          valueToDisplay = `${item.method} for ${item.nb_half_day} half-days.`;
        } else if (item.category === "food") {
          valueToDisplay = `${item.method} for ${item.nb_of_meals} meal.`;
        }
        i += 1;
        return valueToDisplay;
      });
    console.log("DEFAULT", defaultValueForm);
    setDefaultValue(defaultValueForm);
    console.log("PUSH ID PAYMENT", idPayment);
    setBeneficiaryPopupState(true);
  };

  const [newTravelName, setNewTravelName] = useState('');

  useEffect(() => {
    const TravelName = localStorage.getItem('NameTravel');
    if (TravelName !== null) {
      setNewTravelName(TravelName);
    }
  }, [])

  return (
    <>
      <h1>Details of your trip</h1>
      {FillInfoTable ? (
        <TableInfoTravelPayment
          data={data}
          TotalPayments={TotalPayments}
          dataSource={dataSource}
          onDeletePayment={(paymentIndex: number) => handleDeletePayment(paymentIndex)}
          onEditPayment={(paymentIndex: number) => handleEditPayment(paymentIndex, indexOperation)}
        />
      ) : null
      }
      <div style={CreatePaymentStyle}>
        <Button type="primary" onClick={() => handleEditPayment(-1, -1)}>
          Create or add a payment or beneficiary
        </Button>


        <div style={containerStyle}>
          <Button type="primary" onClick={() => setPopupFinalOpen(true)} style={buttonStyle}>
            Add a trip
          </Button>
        </div>
      </div>
      <PassPayments Refresh={Refresh} RefreshActualize={RefreshActualize} TotalCo2Emis={TotalCo2Emis} />

      {BeneficiaryPopupState ? (
        <CreateOrAddBeneficiary visible={BeneficiaryPopupState} onClose={closeBeneficiaryPopup} index={indexOperation} initialValue={initialValueFormCreationBeneficiary} setIndexOperation={setIndexOperation} setInitialValueFormCreationBeneficiary={setInitialValueFormCreationBeneficiary} RefreshActualize={RefreshActualize} Refresh={Refresh} defaultValue={defaultValue} arrayAllFormCategories={arrayAllFormCategories} setArrayAllCategories={setArrayAllCategories} setDefaultValue={setDefaultValue} idPayment={idPayment} />
      ) : null}
      {PopupFinalOpen ? (
        <>
          <Modal
            title={<h1>Summary of my trip : {newTravelName}</h1>}
            visible={PopupFinalOpen}
            onCancel={handleCancel}
            width={1000}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={handlePaymentEngine}>
                Add a trip
              </Button>,
            ]}
          >
            {FillInfoTable ? (
              <TableInfoTravelPayment
                data={data}
                TotalPayments={TotalPayments}
                dataSource={dataSource}
                onDeletePayment={(paymentIndex: number) => handleDeletePayment(paymentIndex)}
                onEditPayment={(paymentIndex: number) => handleEditPayment(paymentIndex, indexOperation)}
              />
            ) : null
            }
            <br />
            <h2>The carbon footprint of my trip : {TotalCo2Emis} tCO2e </h2>
            <br />
          </Modal>
        </>
      ) : null
      }
    </>
  );
}

const containerStyle: CSSProperties = {
  marginTop: '22px',
  textAlign: 'center',
};

const CreatePaymentStyle: CSSProperties = {
  marginTop: '20px',
  float: 'right',
};


const buttonStyle: CSSProperties = {
  alignSelf: 'flex-end',
};

export default CalculationCo2;

