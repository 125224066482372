import React from 'react';

enum KeeweColorsEnum {
  DEFAULT = '#08262E',
  WHITE = 'white',
}

interface LogoInlineProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: KeeweColorsEnum | string;
  height?: number;
}

const LogoInline = ({ color, height, ...props }: LogoInlineProps) => {
  return (
    <div {...props}>
      <svg height={height + 'px'} viewBox="0 0 86 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 22.5306L0.0313009 0H3.85005V13.7687L10.032 5.63265H14.7429L8.18527 14.0816L15.3063 22.5306H10.3137L3.85005 14.3946V22.5306H0Z"
          fill={color}
        />
        <path
          d="M22.3747 23C20.6636 23 19.1611 22.6297 17.8674 21.8891C16.5736 21.1485 15.5615 20.1211 14.8311 18.8068C14.1112 17.4925 13.7513 15.98 13.7513 14.2694C13.7513 12.4231 14.106 10.822 14.8155 9.46598C15.525 8.09954 16.511 7.04082 17.7735 6.28979C19.0359 5.53877 20.4967 5.16326 22.1556 5.16326C23.9085 5.16326 25.3953 5.57528 26.6161 6.39932C27.8472 7.21292 28.7602 8.36553 29.3549 9.85714C29.9496 11.3488 30.174 13.1063 30.0279 15.1299H26.2874V13.7531C26.277 11.9172 25.9535 10.5769 25.3171 9.73197C24.6806 8.88707 23.679 8.46462 22.3121 8.46462C20.7679 8.46462 19.6202 8.94444 18.869 9.90408C18.1178 10.8533 17.7422 12.2458 17.7422 14.0816C17.7422 15.7923 18.1178 17.117 18.869 18.0558C19.6202 18.9946 20.7158 19.4639 22.1556 19.4639C23.0842 19.4639 23.8824 19.2605 24.5502 18.8537C25.2284 18.4365 25.7501 17.8367 26.1152 17.0544L29.8401 18.181C29.1932 19.7039 28.1915 20.8878 26.8352 21.7327C25.4892 22.5775 24.0024 23 22.3747 23ZM16.5527 15.1299V12.2823H28.1811V15.1299H16.5527Z"
          fill={color}
        />
        <path
          d="M39.3263 23C37.6152 23 36.1127 22.6297 34.8189 21.8891C33.5251 21.1485 32.5131 20.1211 31.7827 18.8068C31.0628 17.4925 30.7028 15.98 30.7028 14.2694C30.7028 12.4231 31.0576 10.822 31.7671 9.46598C32.4766 8.09954 33.4625 7.04082 34.725 6.28979C35.9875 5.53877 37.4482 5.16326 39.1072 5.16326C40.8601 5.16326 42.3469 5.57528 43.5676 6.39932C44.7988 7.21292 45.7118 8.36553 46.3065 9.85714C46.9012 11.3488 47.1255 13.1063 46.9795 15.1299H43.239V13.7531C43.2285 11.9172 42.9051 10.5769 42.2686 9.73197C41.6322 8.88707 40.6305 8.46462 39.2637 8.46462C37.7195 8.46462 36.5718 8.94444 35.8206 9.90408C35.0693 10.8533 34.6937 12.2458 34.6937 14.0816C34.6937 15.7923 35.0693 17.117 35.8206 18.0558C36.5718 18.9946 37.6673 19.4639 39.1072 19.4639C40.0358 19.4639 40.834 19.2605 41.5017 18.8537C42.1799 18.4365 42.7016 17.8367 43.0668 17.0544L46.7916 18.181C46.1448 19.7039 45.1431 20.8878 43.7867 21.7327C42.4408 22.5775 40.954 23 39.3263 23ZM33.5043 15.1299V12.2823H45.1327V15.1299H33.5043Z"
          fill={color}
        />
        <path
          d="M51.5817 22.5306L46.417 5.60136L50.1105 5.63265L53.4128 16.4755L56.7621 5.63265H59.9391L63.2727 16.4755L66.5906 5.63265H70.2842L65.1195 22.5306H62.1772L58.3428 10.9054L54.524 22.5306H51.5817Z"
          fill={color}
        />
        <path
          d="M78.3037 23C76.5925 23 75.0901 22.6297 73.7963 21.8891C72.5025 21.1485 71.4904 20.1211 70.7601 18.8068C70.0401 17.4925 69.6802 15.98 69.6802 14.2694C69.6802 12.4231 70.0349 10.822 70.7444 9.46598C71.4539 8.09954 72.4399 7.04082 73.7024 6.28979C74.9649 5.53877 76.4256 5.16326 78.0845 5.16326C79.8374 5.16326 81.3242 5.57528 82.545 6.39932C83.7761 7.21292 84.6891 8.36553 85.2838 9.85714C85.8785 11.3488 86.1029 13.1063 85.9568 15.1299H82.2163V13.7531C82.2059 11.9172 81.8824 10.5769 81.246 9.73197C80.6095 8.88707 79.6079 8.46462 78.2411 8.46462C76.6969 8.46462 75.5491 8.94444 74.7979 9.90408C74.0467 10.8533 73.6711 12.2458 73.6711 14.0816C73.6711 15.7923 74.0467 17.117 74.7979 18.0558C75.5491 18.9946 76.6447 19.4639 78.0845 19.4639C79.0131 19.4639 79.8113 19.2605 80.4791 18.8537C81.1573 18.4365 81.679 17.8367 82.0442 17.0544L85.769 18.181C85.1221 19.7039 84.1205 20.8878 82.7641 21.7327C81.4181 22.5775 79.9313 23 78.3037 23ZM72.4816 15.1299V12.2823H84.11V15.1299H72.4816Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default LogoInline;

LogoInline.defaultProps = {
  color: KeeweColorsEnum.DEFAULT,
  height: 23,
};
