import { Layout, Menu, ConfigProvider, Button, Drawer, Steps, Space, Segmented, Badge, List, Anchor, notification, Spin } from 'antd';
import React, { useState, useEffect, CSSProperties } from 'react';
import styled from 'styled-components';
import FormTransport from '../molecules/FormTransport';
import StepsTravel from '../atoms/StepsTravel';
import FormAccomodation from '../molecules/FormAccomodation';
import FormActivities from '../molecules/FormActivities';
import FormFood from '../molecules/FormFood';
import CalculationCo2 from '../molecules/CalculationCo2';
import UploadFile from '../molecules/UploadFile';
import { CheckCircleFilled, CheckCircleOutlined, CloseCircleFilled, HomeOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBowlFood, faCocktail, faHome, faPersonSkiing, faPlane } from '@fortawesome/free-solid-svg-icons';
import GetTcO2Actual from '../molecules/GetTcO2Actual';
import { CheckFormError, CheckFormNameTravel, NotificationType } from '../molecules/ErrorForm';


const ValueFormTransport = {
    transport: [

    ],
};

const ValueFormAccomodation = {
    accomodation: [],
};

const ValueFormActivities = {
    activities: [],
};

const ValueFormFood = {
    food: [],
};

const ValueFormPayment = {
    payment: [],
};

/*const ValueFormAccomodatio = {
    "accomodation": [
      {
        "method": "Hotel", // Payment method for accommodation
        "country": "France", // Location of accommodation
        "nb_of_nights": "5", // Number of nights stayed
        "id_payment": "1" // Payment ID for accommodation
      }
    ]
  }

const ValueFormActivitie = {
    "activities": [
      {
        "method": "Jetski", // Payment method for activities
        "nb_half_day": "5", // Number of half-days for activities
        "id_payment": "1" // Payment ID for activities
      }
    ]
  }

  const ValueFormFoo = {
    "food": [
      {
        "method": "Végétarien", // Payment method for food
        "nb_of_meals": "3", // Number of meals
        "id_payment": "1" // Payment ID for food
      }
    ]
  }

  localStorage.setItem('FormAccomodation', JSON.stringify(ValueFormAccomodatio));
  localStorage.setItem('FormActivities', JSON.stringify(ValueFormActivitie));
  localStorage.setItem('FormFood', JSON.stringify(ValueFormFoo));
  */

  /*const formAccomodation = JSON.parse(localStorage.getItem('FormAccomodation') || '{}');
  const formActivities = JSON.parse(localStorage.getItem('FormActivities') || '{}');
  const formFood = JSON.parse(localStorage.getItem('FormFood') || '{}');
  const formTransport = JSON.parse(localStorage.getItem('FormTransport') || '{}');

  console.log("FORM ACCOMODATION", formAccomodation);
  console.log("FORM ACTIVITIES", formActivities);
  console.log("FORM FOOD", formFood);
  console.log("FORM TRANSPORT", formTransport);

  localStorage.setItem('FormPayment', JSON.stringify(ValueFormPayment));
  localStorage.setItem('FormTransport', JSON.stringify(ValueFormTransport));
  localStorage.setItem('FormTransportDistance', '{}');
  localStorage.setItem('FormTransportMethod', '{}');*/

const CheckCircleFill = (FormType: string, type: string) => {
    const Array = localStorage.getItem(FormType);
    if (Array !== null) {
        console.log("ARRAYY", Array, FormType);
        const NewArray = JSON.parse(Array);
        console.log(NewArray);
        if (NewArray[type].length > 0) {
            return true;
        }
    }
    return false;
}

interface RefreshInterface {
    Refresh: number;
    RefreshActualize: () => void;
}

const AddTripDrawer = ({ Refresh, RefreshActualize }: RefreshInterface) => {
    const [open, setOpen] = useState(false);

    const [calculTransport, setCalculTransport] = useState(-1);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setFormBeneficiariesOpen(false);
        localStorage.setItem('FormTransport', JSON.stringify(ValueFormTransport));
        localStorage.setItem('FormAccomodation', JSON.stringify(ValueFormAccomodation));
        localStorage.setItem('FormActivities', JSON.stringify(ValueFormActivities));
        localStorage.setItem('FormFood', JSON.stringify(ValueFormFood));
        localStorage.setItem('FormPayment', JSON.stringify(ValueFormPayment));
        localStorage.setItem('FormTransportDistance', JSON.stringify("[]"));
        localStorage.setItem('FormTransportMethod', JSON.stringify("[]"));
        localStorage.setItem('FormImageName', '[]');
        localStorage.setItem('NameTravel', '');
        RefreshActualize();
    };

    const [TransportPressed, setTransportPressed] = useState(false);
    const [AccomodationPressed, setAccomodationPressed] = useState(false);
    const [ActivitiesPressed, setActivitiesPressed] = useState(false);
    const [FoodPressed, setFoodPressed] = useState(false);
    const [FormBeneficiariesOpen, setFormBeneficiariesOpen] = useState(false);

    const toggleTransportState = () => {
        setTransportPressed(!TransportPressed);
    };

    const toggleAccomodationState = () => {
        setAccomodationPressed(!AccomodationPressed);
    };

    const toggleActivitiesState = () => {
        setActivitiesPressed(!ActivitiesPressed);
    };

    const toggleFoodState = () => {
        setFoodPressed(!FoodPressed);
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (FormError: string) => {
        let DescriptionForm: string = '';
        switch (FormError) {
            case 'FormTransport':
                DescriptionForm = 'Transport';
                break;
            case 'FormAccomodation':
                DescriptionForm = 'Accomodation';
                break;
            case 'FormActivities':
                DescriptionForm = 'Activities';
                break;
            case 'FormFood':
                DescriptionForm = 'Food';
                break;
            case 'NameTravel':
                DescriptionForm = ``;
        }

        api['error']({
            message: 'Error in form',
            description:
                `Missing information in the ${DescriptionForm} form`,
        });
    };

    const toggleBeneficiaryState = () => {
        if (CheckFormError('FormAccomodation', 'accomodation', 4) === true) {
            openNotificationWithIcon('FormAccomodation');
        } else if (CheckFormError('FormActivities', 'activities', 3) === true) {
            openNotificationWithIcon('FormActivities');
        } else if (CheckFormError('FormFood', 'food', 3) === true) {
            openNotificationWithIcon('FormFood');
        } else if (CheckFormNameTravel() === true) {
            openNotificationWithIcon('NameTravel');
        } else {
            setTransportPressed(false);
            setAccomodationPressed(false);
            setActivitiesPressed(false);
            setFoodPressed(false);
            setFormBeneficiariesOpen(!FormBeneficiariesOpen);
        }
    };

    const toggleBackBeneficiaryState = () => {
        setTransportPressed(false);
        setAccomodationPressed(false);
        setActivitiesPressed(false);
        setFoodPressed(false);
        setFormBeneficiariesOpen(!FormBeneficiariesOpen);
    }

    //localStorage.setItem('TableAllTravels', '[]');

    const [OneTransportSelected, setOneTransportSelected] = useState(false);

    return (
        <>
            <Button type="primary" onClick={showDrawer}>
            Add a trip
            </Button>
            <Drawer width="736" title="Add a trip" placement="right" onClose={onClose} open={open}>
                {!FormBeneficiariesOpen && (
                    <>
                        <div style={{ display: 'flex' }}>
                            <UploadFile Refresh={Refresh} RefreshActualize={RefreshActualize} calculTransport={calculTransport} setCalculTransport={setCalculTransport} />
                            <div style={{ marginLeft: '10px' }}>
                                {Refresh > 0 && <GetTcO2Actual Refresh={Refresh} />}
                            </div>
                        </div>
                        <div style={{ marginTop: '195px' }}>
                            <Badge count={CheckCircleFill('FormTransport', 'transport') ? <CheckCircleOutlined style={{ color: '#52c41a' }} /> : null}>

                                <Button
                                    type="default"
                                    size="large"
                                    icon={<FontAwesomeIcon icon={faPlane} size="4x" />}
                                    style={{ backgroundColor: TransportPressed ? '#22778E' : 'white', color: TransportPressed ? 'white' : '#22778E', border: `1px solid ${TransportPressed ? 'white' : '#22778E'}`, width: '100px', height: '100px', marginLeft: '0px', borderRadius: '41px' }}
                                    onClick={toggleTransportState}
                                >
                                </Button>
                            </Badge>
                            <Badge count={CheckCircleFill('FormAccomodation', 'accomodation') ? <CheckCircleOutlined style={{ color: '#52c41a' }} /> : null}>

                                <Button
                                    type="default"
                                    size="large"
                                    icon={<FontAwesomeIcon icon={faHome} size="4x" />}
                                    style={{ backgroundColor: AccomodationPressed ? '#22778E' : 'white', color: AccomodationPressed ? 'white' : '#22778E', border: `1px solid ${AccomodationPressed ? 'white' : '#22778E'}`, width: '100px', height: '100px', marginLeft: '10px' }}
                                    onClick={toggleAccomodationState}
                                >
                                </Button>
                            </Badge>

                            <Badge count={CheckCircleFill('FormActivities', 'activities') ? <CheckCircleOutlined style={{ color: '#52c41a' }} /> : null}>
                                <Button
                                    type="default"
                                    size="large"
                                    icon={<FontAwesomeIcon icon={faPersonSkiing} size="4x" />}
                                    style={{ backgroundColor: ActivitiesPressed ? '#22778E' : 'white', color: ActivitiesPressed ? 'white' : '#22778E', border: `1px solid ${ActivitiesPressed ? 'white' : '#22778E'}`, width: '100px', height: '100px', marginLeft: '10px' }}
                                    onClick={toggleActivitiesState}
                                >
                                </Button>
                            </Badge>
                            <Badge count={CheckCircleFill('FormFood', 'food') ? <CheckCircleOutlined style={{ color: '#52c41a' }} /> : null}>

                                <Button
                                    type="default"
                                    size="large"
                                    icon={<FontAwesomeIcon icon={faCocktail} size="4x" />}
                                    style={{ backgroundColor: FoodPressed ? '#22778E' : 'white', color: FoodPressed ? 'white' : '#22778E', border: `1px solid ${FoodPressed ? 'white' : '#22778E'}`, width: '100px', height: '100px', marginLeft: '10px' }}
                                    onClick={toggleFoodState}
                                >
                                </Button>
                            </Badge>
                        </div>
                    </>
                )}


                {TransportPressed && (Refresh > 0) && (
                    <FormTransport Refresh={Refresh} RefreshActualize={RefreshActualize} calculTransport={1} setCalculTransport={setCalculTransport} />
                )}

                {AccomodationPressed && (Refresh > 0) && (
                    <FormAccomodation Refresh={Refresh} RefreshActualize={RefreshActualize} />
                )}

                {ActivitiesPressed && (
                    <FormActivities Refresh={Refresh} RefreshActualize={RefreshActualize} />
                )}

                {FoodPressed && (
                    <FormFood Refresh={Refresh} RefreshActualize={RefreshActualize} />
                )}

                {FormBeneficiariesOpen ? (
                    <>
                        <div style={BackButtonStyle}>
                            <CalculationCo2 Refresh={Refresh} RefreshActualize={RefreshActualize} onClose={onClose}/>
                            <br />
                            <Button type="primary" onClick={toggleBackBeneficiaryState}>
                                Back
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <br /><br />
                        {contextHolder}
                        <Button type="primary" onClick={toggleBeneficiaryState}>
                            See all my trip details
                        </Button>
                    </>
                )}
            </Drawer>
        </>
    );
}

const BackButtonStyle: CSSProperties = {
    marginTop: '10px',
    textAlign: 'center',
};

export default AddTripDrawer;
