import React from 'react';
import { Button, notification, Space } from 'antd';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const CheckFormError = (FormType: string, type: string, NumberOfInput: number) => {
    const Array = localStorage.getItem(FormType);
    if (Array !== null) {
        const NewArray = JSON.parse(Array);
        const ArrayToParse = NewArray[type];
        for (let i = 0; i < ArrayToParse.length; ++i) {
            if (ArrayToParse[i] === null) {
                return true;
            }
            const sizeObject = Object.keys(ArrayToParse[i]).length;
            if (sizeObject !== NumberOfInput) {
                return true;
            }
        }
        const areAllElementsFilled = NewArray[type].every((item: string) => {
            const values = Object.values(item);
            return values.every((value) => value !== undefined && value !== null && value !== '');
          });
        if (areAllElementsFilled === false) {
            return true;
        }
    }
    return false;
}

export const CheckFormNameTravel = () => {
    const NameTravel = localStorage.getItem('NameTravel');
    console.log("NAME TRAVEL", NameTravel);
    if (NameTravel?.length === 0 || NameTravel === null) {
        return true;
    }
    return false;
}