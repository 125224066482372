import React, { CSSProperties, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Space, Typography, Select } from 'antd';

const { Option } = Select;

interface RefreshInterface {
  Refresh: number;
  RefreshActualize: () => void;
}

const FormFood = ({ Refresh, RefreshActualize }: RefreshInterface) => {
  const [form] = Form.useForm();

  const storedValues = localStorage.getItem('FormFood');
  const initialValue = storedValues ? JSON.parse(storedValues) : {};

  const methodOptions = ["Fish", "Poultry Meat", "Red Meat", "Vegan", "Vegetarian", "Continental Breakfast"];

  const updateLocalStorage = (values: []) => {
    localStorage.setItem('FormFood', JSON.stringify(values));
    RefreshActualize();
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      form={form}
      name="dynamic_form_complex"
      style={{ maxWidth: 600 }}
      autoComplete="off"
      initialValues={initialValue}
      onValuesChange={(changedValues, allValues) => {
        updateLocalStorage(allValues);
      }}
    >
      <h1>My Meals</h1>
      <h3>
        <div style={{display: 'flex'}}>
          <div style={AllTitleFood}>Type of meal</div>
          <div style={AllTitleFood}>Number of meals</div>
        </div>
      </h3>
      <Form.List name="food">
        {(fields, { add, remove }) => (
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
            {fields.map((field) => (
              <div key={field.key} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <Form.Item noStyle name={[field.name, "method"]}>
                    <Select
                      placeholder="Enter a meal type"
                      dropdownStyle={{ width: '300px' }}
                      style={{ marginRight: '8px', width: '290px', marginLeft: '5px' }}
                      
                    >
                      {methodOptions.map((option, index) => (
                        <Option key={index} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle name={[field.name, "nb_of_meals"]}>
                    <Input placeholder="Enter a number of meals" style={{width: '290px', marginLeft: '5px'}}/>
                  </Form.Item>
                  <Form.Item noStyle name={[field.name, "id_payment"]} initialValue={form.getFieldValue([field.name, "id_payment"]) || 0}>
                  </Form.Item>

                </div>
                <CloseOutlined
                  onClick={() => {
                    remove(field.name);
                  }}
                  style={{ marginLeft: '8px', cursor: 'pointer'}}
                />
              </div>
            ))}
            <Button type="dashed" onClick={() => add()} block>
            + Add a restore mode
            </Button>
          </div>
        )}
      </Form.List>
    </Form>
  );
};

const AllTitleFood: CSSProperties = {
  flex: 1,
  margin: '5px',
  padding: '10px',
  textAlign: 'center',
  border: '1px solid #22778E',
  borderRadius: '10px',
};

export default FormFood;
