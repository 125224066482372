import React, { CSSProperties, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Space, Typography, Select } from 'antd';
import { AccomodationCountry } from '../atoms/AccomodationCountry';

const { Option } = Select;

interface RefreshInterface {
  Refresh: number;
  RefreshActualize: () => void;
}

const FormAccomodation = ({ Refresh, RefreshActualize }: RefreshInterface) => {
  const [form] = Form.useForm();

  const countryOptions = AccomodationCountry;

  const storedValues = localStorage.getItem('FormAccomodation');
  const initialValue = storedValues ? JSON.parse(storedValues) : {};
  console.log("INIT VALUE ACCOMODATION", initialValue);
  const methodOptions = ["Hotel", "Camping", "House swapping"];

  useEffect(() => {
    const storedValues = localStorage.getItem('FormAccomodation');
    const initialValue = storedValues ? JSON.parse(storedValues) : {};
    console.log("INIT VALUE ACCOMODATION", initialValue);
    const methodOptions = ["Hotel", "Camping", "Echange de maison"];
  }, [Refresh])

  const updateLocalStorage = (values: []) => {
    console.log("VALUES", values);
    localStorage.setItem('FormAccomodation', JSON.stringify(values));
    RefreshActualize();
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      form={form}
      name="dynamic_form_complex"
      style={{ maxWidth: 600 }}
      autoComplete="off"
      initialValues={initialValue}
      onValuesChange={(changedValues, allValues) => {
        updateLocalStorage(allValues);
      }}
    >
      <h1>My accommodation</h1>
      <h3>
        <div style={{ display: 'flex' }}>
          <div style={AllTitleAccomodation}>Type of accommodation</div>
          <div style={AllTitleAccomodation}>Location</div>
          <div style={AllTitleAccomodation}>Number of nights</div>
        </div>
      </h3>
      <Form.List name="accomodation">
        {(fields, { add, remove }) => (
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
            {fields.map((field) => (
              <div key={field.key} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <Form.Item noStyle name={[field.name, "id_payment"]} initialValue={-1}>
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item noStyle name={[field.name, "method"]}>
                    <Select
                      placeholder="Choose a type"
                      dropdownStyle={{ width: '300px' }}
                      style={{ width: '190px', marginLeft: '5px' }}
                    >
                      {methodOptions.map((option, index) => (
                        <Option key={index} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle name={[field.name, "country"]}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select a country"
                      dropdownStyle={{ width: '300px' }}
                      style={{ width: '190px', marginLeft: '10px' }}
                      onChange={(value) => {
                        form.setFieldsValue({ [field.name]: { ...form.getFieldValue(field.name), country: value } });
                      }}
                    >
                      {countryOptions.map((country, index) => (
                        <Option key={index} value={country}>
                          {country}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle name={[field.name, "nb_of_nights"]}>
                    <Input placeholder="Enter the number of nights" style={{ width: '190px', marginLeft: '10px' }} />
                  </Form.Item>


                </div>
                <CloseOutlined
                  onClick={() => {
                    remove(field.name);
                  }}
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                />
              </div>
            ))}
            <Button type="dashed" onClick={() => add()} block>
              + Add hosting
            </Button>
          </div>
        )}
      </Form.List>
    </Form>
  );
};


const AllTitleAccomodation: CSSProperties = {
  flex: 1,
  margin: '5px',
  padding: '10px',
  textAlign: 'center',
  border: '1px solid #22778E',
  borderRadius: '10px',
};

export default FormAccomodation;