import { CheckCircleFilled } from '@ant-design/icons';
import { Layout, Menu, ConfigProvider, Button, Drawer, Steps, Table, notification, Modal, Card, Result } from 'antd';
import { Console } from 'console';
import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import FormTransport from './FormTransport';
import ReactDOMServer from 'react-dom/server';

interface Payments {
    name: string;
    montant: string;
    contre_valeur_euros: string;
    devise: string;
    taux: string;
    tco2emis: string;
    tco2compense: string;
}

interface RefreshInterface {
    Refresh: number;
    RefreshActualize: () => void;
    TotalCo2Emis: string;
}

function PassPayments({ Refresh, RefreshActualize, TotalCo2Emis }: RefreshInterface) {
    const [ArrayTravel, setArrayTravel] = useState<Payments[]>([]);
    const [FootPrintIssued, setFootPrintIssued] = useState('');
    const [FootPrintCompensed, setFootPrintCompensed] = useState('');

    console.log(TotalCo2Emis);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        const AllPayments = localStorage.getItem('FormPayment');
        if (AllPayments !== null) {
            const NewAllPayments = JSON.parse(AllPayments);
            const newArrayPayments = NewAllPayments.payment;
            if (Array.isArray(newArrayPayments)) {
                const dataSource = newArrayPayments.map((item, index) => ({
                    key: (index + 1).toString(),
                    name: item.name,
                    montant: item.montant,
                    contre_valeur_euros: item.contre_valeur_euros,
                    devise: item.devise,
                    taux: item.taux,
                    tco2emis: item.tco2emis,
                    tco2compense: item.tco2compense,
                }))
                let FootPrintCompensed = 0;
                for (let i = 0; i < dataSource.length; ++i) {
                    FootPrintCompensed += Number(dataSource[i].tco2compense);
                }

                console.log("DATASOURCE", dataSource);
                setFootPrintIssued(TotalCo2Emis);

                setFootPrintCompensed(FootPrintCompensed.toFixed(2).toString());
                setArrayTravel(dataSource);
                console.log(newArrayPayments);
            } else {
                console.error("newArrayPayments is not an array");
            }
        }
    }, [Refresh]);

    /*const TableAllTravels = localStorage.getItem('TableAllTravels');
    if (TableAllTravels !== null) {
        const NewTableAllTravels = JSON.parse(TableAllTravels);
        setFootPrintIssued(NewTableAllTravels[index].co2emis);
        setFootPrintCompensed(NewTableAllTravels[index].co2compense);

        const FinalArray = NewTableAllTravels[index].payments;
        console.log(JSON.stringify(FinalArray));

        const dataSource = FinalArray.name.map((name: string, index: number) => ({
            key: (index + 1).toString(),
            name: name,
            montant: FinalArray.montant[index],
            contre_valeur_euros: FinalArray.contre_valeur_euros[index],
            devise: FinalArray.devise[index],
            taux: FinalArray.taux[index]
        }));

        setArrayTravel(dataSource);
    }*/


    const columns = [
        {
            title: 'Bénéficiaire',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Montant',
            dataIndex: 'montant',
            key: 'montant',
        },
        {
            title: 'Devise',
            dataIndex: 'devise',
            key: 'devise',
        },
        {
            title: 'Taux',
            dataIndex: 'taux',
            key: 'taux',
        },
        {
            title: 'Contre valeur',
            dataIndex: 'contre_valeur_euros',
            key: 'contre_valeur_euros',
        },
    ];


    const openNotificationWithContent = () => {
        RefreshActualize();
        console.log("ARRAY TRAVEL", ArrayTravel);
        notification.open({
            message: 'Validating your payments',
            description:
                <>
                    <Result
                        status="success"
                        title="Operations carried out"
                        subTitle="Order number: 2017182818828182881"
                        extra={[
                            <Card size="small" title="Your operations" style={{ width: 300, marginLeft: '80px' }}>
                                {ArrayTravel.map((item, key: number) => (
                                    <div key={key}>
                                        <p>{item.name} : {item.montant} {item.devise} </p>
                                    </div>
                                ))}
                            </Card>,
                            <Card style={{ width: 300, marginTop: '10px', marginLeft: '80px' }}>
                                <p>The carbon footprint of your international operation</p>
                                <h2>{TotalCo2Emis} T Co2e</h2>
                            </Card>,
                            <Card style={{ width: 300, marginTop: '10px', marginLeft: '80px' }}>
                                <p>Automatic compensation with Keewe</p>
                                <h2>{FootPrintCompensed} T Co2e</h2>
                            </Card>,
                        ]}
                        style={{
                            backgroundColor: 'lightblue',
                            width: '500px',
                            border: '2px solid',
                            borderRadius: '8px',
                            padding: '16px',
                        }}
                    >

                    </Result>

                </>,
            duration: 10,
            className: 'custom-class',
            style: {
                width: 600,
            },
        });
    };

    return (
        <>
            <div style={PaymentPassButton}>
                <Button type="primary" onClick={openNotificationWithContent}>
                    Making payments
                </Button>
            </div>
        </>
    )
}

const PaymentPassButton: CSSProperties = {
    marginTop: '20px',
    textAlign: 'center',
};


export default PassPayments;
